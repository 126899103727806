import GameObject from '../game-object/GameObject'
import Terrain from './terrain/Terrain'
import TerrainRenderer from './terrain/TerrainRenderer'
import SkyboxRenderer from './SkyboxRenderer'
import GameObjectRenderer from './GameObjectRenderer'
import WebGL from '../core/WebGL'
import Vector3 from '../math/Vector3'
import Environment from '../core/Environment'
import GameObjectLight from '../game-object/GameObjectLight'
import Camera from '../game-object/Camera'
import Matrix4 from '../math/Matrix4'
import Matrices from '../math/Matrices'
import Scene from '../scene/Scene'
import Assets from '../../core/Assets'
import Player from '../game-object/Player'
import Sun from '../game-object/Sun'
import Vector4 from '../math/Vector4'
import WaterTile from './water/WaterTile'
import Loader from '../game-object/loader/Loader'
import OBJLoader from '../game-object/loader/OBJLoader'
import SceneModelDefinition from '../scene/SceneModelDefinition'

/**
 * The master renderer.
 * 
 * @author Stan Hurks
 */
export default class MasterRenderer {

    /**
     * The current scene
     */
    public static currentScene: Scene|null = null

    /**
     * All the game objects in the current scene
     */
    public static gameObjects: GameObject[] = []

    /**
     * All the terrains in the current scene
     */
    public static terrains: Terrain[] = []

    /**
     * All lights in the current scene
     */
    public static lights: GameObjectLight[] = []

    /**
     * The sun in the current scene
     */
    public static sun: Sun|null = null

    /**
     * All water tiles in the current scene
     */
    public static waterTiles: WaterTile[] = []

    constructor (
        private gameObjectRenderer: GameObjectRenderer,
        private terrainRenderer: TerrainRenderer,
        private skyboxRenderer: SkyboxRenderer
    ) {}

    /**
     * Enable back faced culling
     */
    public static enableCulling = () => {
        const gl = WebGL.context
        gl.enable(gl.CULL_FACE)
        gl.cullFace(gl.BACK)
    }

    /**
     * Disable back faced culling
     */
    public static disableCulling = () => {
        const gl = WebGL.context
        gl.disable(gl.CULL_FACE)
    }

    /**
     * Load a scene
     */
    public static loadScene = (name: string) => {
        // Set the current scene to null
        MasterRenderer.currentScene = null

        // Load the scene from the assets
        Assets.load(`scenes/${name}/scene-${name}.json`).then((json) => {
            MasterRenderer.initializeScene(JSON.parse(json))
        }).catch((err) => {
            console.error(`Could not load scene: "${name}": ${err}`)
        })
    }

    /**
     * Initialize the current scene
     */
    private static initializeScene = (scene: Scene) => {
        MasterRenderer.currentScene = scene

        // Reset all values
        MasterRenderer.terrains = []
        MasterRenderer.gameObjects = []
        MasterRenderer.waterTiles = []
        MasterRenderer.lights = []
        MasterRenderer.sun = null

        // All promises
        const promises: Promise<any>[] = []
        for (const sceneTerrain of scene.terrains) {
            
            // Initialize the terrain
            const terrain = new Terrain(sceneTerrain.position.x, sceneTerrain.position.z)

            // Load the textures
            terrain.textures = {
                black: sceneTerrain.textures.black.location
                    ? Loader.loadTexture(sceneTerrain.textures.black.location, true,
                        new Vector4(
                            sceneTerrain.textures.black.color[0],
                            sceneTerrain.textures.black.color[1],
                            sceneTerrain.textures.black.color[2],
                            sceneTerrain.textures.black.color[3]
                        ))
                    : Loader.loadColorAsTexture(new Vector4(
                        sceneTerrain.textures.black.color[0],
                        sceneTerrain.textures.black.color[1],
                        sceneTerrain.textures.black.color[2],
                        sceneTerrain.textures.black.color[3]
                    )),
                red: sceneTerrain.textures.red.location
                    ? Loader.loadTexture(sceneTerrain.textures.red.location, true,
                        new Vector4(
                            sceneTerrain.textures.red.color[0],
                            sceneTerrain.textures.red.color[1],
                            sceneTerrain.textures.red.color[2],
                            sceneTerrain.textures.red.color[3]
                        ))
                    : Loader.loadColorAsTexture(new Vector4(
                        sceneTerrain.textures.red.color[0],
                        sceneTerrain.textures.red.color[1],
                        sceneTerrain.textures.red.color[2],
                        sceneTerrain.textures.red.color[3]
                    )),
                green: sceneTerrain.textures.green.location
                    ? Loader.loadTexture(sceneTerrain.textures.green.location, true,
                        new Vector4(
                            sceneTerrain.textures.green.color[0],
                            sceneTerrain.textures.green.color[1],
                            sceneTerrain.textures.green.color[2],
                            sceneTerrain.textures.green.color[3]
                        ))
                    : Loader.loadColorAsTexture(new Vector4(
                        sceneTerrain.textures.green.color[0],
                        sceneTerrain.textures.green.color[1],
                        sceneTerrain.textures.green.color[2],
                        sceneTerrain.textures.green.color[3]
                    )),
                blue: sceneTerrain.textures.blue.location
                    ? Loader.loadTexture(sceneTerrain.textures.blue.location, true,
                        new Vector4(
                            sceneTerrain.textures.blue.color[0],
                            sceneTerrain.textures.blue.color[1],
                            sceneTerrain.textures.blue.color[2],
                            sceneTerrain.textures.blue.color[3]
                        ))
                    : Loader.loadColorAsTexture(new Vector4(
                        sceneTerrain.textures.blue.color[0],
                        sceneTerrain.textures.blue.color[1],
                        sceneTerrain.textures.blue.color[2],
                        sceneTerrain.textures.blue.color[3]
                    )),
                blendMap: Loader.loadTexture(`scenes/${scene.name}/terrains/${sceneTerrain.name}/blendmap`, false)
            }

            // Generate the terrain
            promises.push(
                new Promise((resolve, reject) => {
                    terrain.generateLowPolyTerrain(`scenes/${scene.name}/terrains/${sceneTerrain.name}/heightmap`).then(() => {
                        MasterRenderer.terrains.push(terrain)
                        resolve()
                    }).catch((error) => {
                        reject(`Could not initialize terrain "${sceneTerrain.name}": ${error}`)
                    })
                })
            )
        }

        // Initialize all game objects
        for (const sceneGameObject of scene.gameObjects) {

            // Load the definition for the model name
            promises.push(
                new Promise((resolve, reject) => {
                    Assets.load(`models/definitions/${sceneGameObject.modelName}.json`).then((stringifiedDefinition) => {
                        
                        // The definition
                        const definition: SceneModelDefinition = JSON.parse(stringifiedDefinition)

                        // Process the model
                        OBJLoader.loadObjModel(sceneGameObject.modelName, {
                            texture: definition.texture.location
                                ? Loader.loadTexture(definition.texture.location, definition.texture.repeatImage || false,
                                    new Vector4(
                                        definition.texture.color[0],
                                        definition.texture.color[1],
                                        definition.texture.color[2],
                                        definition.texture.color[3]
                                    )
                                )
                                : Loader.loadColorAsTexture(new Vector4(
                                    definition.texture.color[0],
                                    definition.texture.color[1],
                                    definition.texture.color[2],
                                    definition.texture.color[3]
                                )),
                            shineDamper: definition.texture.shineDamper,
                            reflectivity: definition.texture.reflectivity,
                            isTransparent: definition.texture.isTransparent,
                            useFakeLighting: definition.texture.useFakeLighting,
                            sprite: definition.texture.sprite
                        }).then((model) => {

                            // The game object
                            const gameObject = new GameObject(
                                model,
                                {
                                    position: new Vector3(
                                        sceneGameObject.transform.position.x,
                                        sceneGameObject.transform.position.y,
                                        sceneGameObject.transform.position.z
                                    ),
                                    rotation: sceneGameObject.transform.rotation
                                        ? new Vector3(
                                            sceneGameObject.transform.rotation.x,
                                            sceneGameObject.transform.rotation.y,
                                            sceneGameObject.transform.rotation.z
                                        )
                                        : new Vector3(0, 0, 0),
                                    scale: sceneGameObject.transform.scale
                                        ? new Vector3(
                                            sceneGameObject.transform.scale.x,
                                            sceneGameObject.transform.scale.y,
                                            sceneGameObject.transform.scale.z
                                        )
                                        : new Vector3(1, 1, 1)
                                },
                                sceneGameObject.light
                            )
                                
                            if (sceneGameObject.id) {
                                gameObject.id = sceneGameObject.id
                            }

                            // Create a game object
                            MasterRenderer.gameObjects.push(gameObject)

                            resolve()
                        }).catch((error) => {
                            reject(`Could not process game object model "${sceneGameObject.modelName}": ${error}`)
                        })
                    }).catch((error) => {
                        reject(`Could not initialize game object model "${sceneGameObject.modelName}": ${error}`)
                    })
                }
            ))
        }

        Promise.all(promises).then(() => {
            MasterRenderer.currentScene = scene
        }).catch((err) => {
            throw new Error(`Could not initialize scene "${scene.name}": ${err}`)
        })
    }

    /**
     * Prepare for rendering
     */
    public prepare = () => {
        const gl = WebGL.context
        gl.enable(gl.DEPTH_TEST)
        gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT)
        const skyColor: Vector3 = Environment.getCurrentSkyColor()
        gl.clearColor(skyColor.x, skyColor.y, skyColor.z, 1)
    }

    /**
     * Render the current scene
     */
    public render = (player: Player, camera: Camera) => {
        // Prepare the rendering
        this.prepare()

        // Create the matrices
        const viewMatrix: Matrix4 = Matrices.createViewMatrix(camera)
        const projectionMatrix: Matrix4 = Matrices.createProjectionMatrix()

        // The actual rendering
        const render = (clipPlane: Vector4) => {
            this.gameObjectRenderer.enable()
            this.gameObjectRenderer.loadLights(MasterRenderer.lights)
            this.gameObjectRenderer.loadViewMatrix(viewMatrix)
            this.gameObjectRenderer.loadProjectionMatrix(projectionMatrix)
            this.gameObjectRenderer.loadFogAmount(Environment.fogAmount)
            this.gameObjectRenderer.loadSkyColor(Environment.getCurrentSkyColor())
            this.gameObjectRenderer.loadClipPlane(clipPlane)
            this.gameObjectRenderer.loadDayLightValue(Environment.getDayLightValue())
            this.gameObjectRenderer.renderEntities(MasterRenderer.gameObjects.concat([player]))
            this.gameObjectRenderer.disable()
    
            this.terrainRenderer.enable()
            this.terrainRenderer.loadLights(MasterRenderer.lights)
            this.terrainRenderer.loadViewMatrix(viewMatrix)
            this.terrainRenderer.loadProjectionMatrix(projectionMatrix)
            this.terrainRenderer.loadSkyColor(Environment.getCurrentSkyColor())
            this.terrainRenderer.loadFogAmount(Environment.fogAmount)
            this.terrainRenderer.loadDayLightValue(Environment.getDayLightValue())
            this.terrainRenderer.loadClipPlane(clipPlane)
            this.terrainRenderer.renderTerrains(MasterRenderer.terrains)
            this.terrainRenderer.disable()
    
            this.skyboxRenderer.enable()
            this.skyboxRenderer.render(camera)
            this.skyboxRenderer.disable()
        }

        // Render the scene
        render(new Vector4(0, 0, 0, 0))
    }

    /**
     * Clean up all programs used by this class
     */
    public cleanUp = () => {
        MasterRenderer.gameObjects = []
        MasterRenderer.terrains = []

        this.gameObjectRenderer.cleanUp()
        this.terrainRenderer.cleanUp()
    }
}