import Vector3 from './Vector3'

/**
 * A 4x4 matrix.
 * 
 * @author Stan Hurks
 */
export default class Matrix4 {

    public m00: number = 0
    public m01: number = 0
    public m02: number = 0
    public m03: number = 0
    public m10: number = 0
    public m11: number = 0
    public m12: number = 0
    public m13: number = 0
    public m20: number = 0
    public m21: number = 0
    public m22: number = 0
    public m23: number = 0
    public m30: number = 0
    public m31: number = 0
    public m32: number = 0
    public m33: number = 0

    /**
     * Clone a matrix
     */
    public static clone = (matrix : Matrix4): Matrix4 => {
        const newMatrix: Matrix4 = new Matrix4()
        newMatrix.m00 = matrix.m00
        newMatrix.m01 = matrix.m01
        newMatrix.m02 = matrix.m02
        newMatrix.m03 = matrix.m03
        newMatrix.m10 = matrix.m10
        newMatrix.m11 = matrix.m11
        newMatrix.m12 = matrix.m12
        newMatrix.m13 = matrix.m13
        newMatrix.m20 = matrix.m20
        newMatrix.m21 = matrix.m21
        newMatrix.m22 = matrix.m22
        newMatrix.m23 = matrix.m23
        newMatrix.m30 = matrix.m30
        newMatrix.m31 = matrix.m31
        newMatrix.m32 = matrix.m32
        newMatrix.m33 = matrix.m33
        return newMatrix
    }

    /**
     * Get the determinant for a 3x3 matrix
     */
    private static determinant3x3 = (
        t00: number, t01: number, t02: number, 
        t10: number, t11: number, t12: number, 
        t20: number, t21: number, t22: number
    ): number => {
        return t00 * (t11 * t22 - t12 * t21)
            + t01 * (t12 * t20 - t10 * t22)
            + t02 * (t10 * t21 - t11 * t20)
    }

    /**
     * Set the idgame object for the matrix.
     */
    public setIdentity = () => {
        this.m00 = 1.0
        this.m01 = 0.0
        this.m02 = 0.0
        this.m03 = 0.0
        this.m10 = 0.0
        this.m11 = 1.0
        this.m12 = 0.0
        this.m13 = 0.0
        this.m20 = 0.0
        this.m21 = 0.0
        this.m22 = 1.0
        this.m23 = 0.0
        this.m30 = 0.0
        this.m31 = 0.0
        this.m32 = 0.0
        this.m33 = 1.0
    }

    /**
     * Translate the matrix
     */
    public translate = (translation: Vector3) => {
        this.m30 += this.m00 * translation.x + this.m10 * translation.y + this.m20 * translation.z
        this.m31 += this.m01 * translation.x + this.m11 * translation.y + this.m21 * translation.z
        this.m32 += this.m02 * translation.x + this.m12 * translation.y + this.m22 * translation.z
        this.m33 += this.m03 * translation.x + this.m13 * translation.y + this.m23 * translation.z
    }

    /**
     * Rotate the matrix
     */
    public rotate = (angle: number, axis: Vector3) => {
        const c: number = Math.cos(angle)
        const s: number = Math.sin(angle)
        const oneminusc: number = 1.0 - c
        const xy: number = axis.x * axis.y
        const yz: number = axis.y * axis.z
        const xz: number = axis.x * axis.z
        const xs: number = axis.x * s
        const ys: number = axis.y * s
        const zs: number = axis.z * s
        const f00: number = axis.x * axis.x * oneminusc + c
        const f01: number = xy * oneminusc + zs
        const f02: number = xz * oneminusc - ys
        const f10: number = xy * oneminusc - zs
        const f11: number = axis.y * axis.y * oneminusc + c
        const f12: number = yz * oneminusc + xs
        const f20: number = xz * oneminusc + ys
        const f21: number = yz * oneminusc - xs
        const f22: number = axis.z * axis.z * oneminusc + c
        const t00: number = this.m00 * f00 + this.m10 * f01 + this.m20 * f02
        const t01: number = this.m01 * f00 + this.m11 * f01 + this.m21 * f02
        const t02: number = this.m02 * f00 + this.m12 * f01 + this.m22 * f02
        const t03: number = this.m03 * f00 + this.m13 * f01 + this.m23 * f02
        const t10: number = this.m00 * f10 + this.m10 * f11 + this.m20 * f12
        const t11: number = this.m01 * f10 + this.m11 * f11 + this.m21 * f12
        const t12: number = this.m02 * f10 + this.m12 * f11 + this.m22 * f12
        const t13: number = this.m03 * f10 + this.m13 * f11 + this.m23 * f12
        this.m20 = this.m00 * f20 + this.m10 * f21 + this.m20 * f22
        this.m21 = this.m01 * f20 + this.m11 * f21 + this.m21 * f22
        this.m22 = this.m02 * f20 + this.m12 * f21 + this.m22 * f22
        this.m23 = this.m03 * f20 + this.m13 * f21 + this.m23 * f22
        this.m00 = t00
        this.m01 = t01
        this.m02 = t02
        this.m03 = t03
        this.m10 = t10
        this.m11 = t11
        this.m12 = t12
        this.m13 = t13
    }

    /**
     * Scale the matrix
     */
    public scale = (scale: Vector3) => {
        this.m00 = this.m00 * scale.x
        this.m01 = this.m01 * scale.x
        this.m02 = this.m02 * scale.x
        this.m03 = this.m03 * scale.x
        this.m10 = this.m10 * scale.y
        this.m11 = this.m11 * scale.y
        this.m12 = this.m12 * scale.y
        this.m13 = this.m13 * scale.y
        this.m20 = this.m20 * scale.z
        this.m21 = this.m21 * scale.z
        this.m22 = this.m22 * scale.z
        this.m23 = this.m23 * scale.z
    }

    /**
     * Calculate the determinant for the matrix.
     */
    public determinant = (): number => {
        let f: number = this.m00 * (this.m11 * this.m22 * this.m33 + this.m12 * this.m23 * this.m31 + this.m13 * this.m21 * this.m32 - this.m13 * this.m22 * this.m31 - this.m11 * this.m23 * this.m32 - this.m12 * this.m21 * this.m33)
        f -= this.m01 * (this.m10 * this.m22 * this.m33 + this.m12 * this.m23 * this.m30 + this.m13 * this.m20 * this.m32 - this.m13 * this.m22 * this.m30 - this.m10 * this.m23 * this.m32 - this.m12 * this.m20 * this.m33)
        f += this.m02 * (this.m10 * this.m21 * this.m33 + this.m11 * this.m23 * this.m30 + this.m13 * this.m20 * this.m31 - this.m13 * this.m21 * this.m30 - this.m10 * this.m23 * this.m31 - this.m11 * this.m20 * this.m33)
        f -= this.m03 * (this.m10 * this.m21 * this.m32 + this.m11 * this.m22 * this.m30 + this.m12 * this.m20 * this.m31 - this.m12 * this.m21 * this.m30 - this.m10 * this.m22 * this.m31 - this.m11 * this.m20 * this.m32)
        return f
    }

    /**
     * Invert the matrix
     */
    public invert() : boolean {
        const determinant : number = this.determinant()
        if (determinant !== 0.0) {
            const determinant_inv: number = 1.0 / determinant
            const t00: number = Matrix4.determinant3x3(this.m11, this.m12, this.m13, this.m21, this.m22, this.m23, this.m31, this.m32, this.m33)
            const t01: number = -Matrix4.determinant3x3(this.m10, this.m12, this.m13, this.m20, this.m22, this.m23, this.m30, this.m32, this.m33)
            const t02: number = Matrix4.determinant3x3(this.m10, this.m11, this.m13, this.m20, this.m21, this.m23, this.m30, this.m31, this.m33)
            const t03: number = -Matrix4.determinant3x3(this.m10, this.m11, this.m12, this.m20, this.m21, this.m22, this.m30, this.m31, this.m32)
            const t10: number = -Matrix4.determinant3x3(this.m01, this.m02, this.m03, this.m21, this.m22, this.m23, this.m31, this.m32, this.m33)
            const t11: number = Matrix4.determinant3x3(this.m00, this.m02, this.m03, this.m20, this.m22, this.m23, this.m30, this.m32, this.m33)
            const t12: number = -Matrix4.determinant3x3(this.m00, this.m01, this.m03, this.m20, this.m21, this.m23, this.m30, this.m31, this.m33)
            const t13: number = Matrix4.determinant3x3(this.m00, this.m01, this.m02, this.m20, this.m21, this.m22, this.m30, this.m31, this.m32)
            const t20: number = Matrix4.determinant3x3(this.m01, this.m02, this.m03, this.m11, this.m12, this.m13, this.m31, this.m32, this.m33)
            const t21: number = -Matrix4.determinant3x3(this.m00, this.m02, this.m03, this.m10, this.m12, this.m13, this.m30, this.m32, this.m33)
            const t22: number = Matrix4.determinant3x3(this.m00, this.m01, this.m03, this.m10, this.m11, this.m13, this.m30, this.m31, this.m33)
            const t23: number = -Matrix4.determinant3x3(this.m00, this.m01, this.m02, this.m10, this.m11, this.m12, this.m30, this.m31, this.m32)
            const t30: number = -Matrix4.determinant3x3(this.m01, this.m02, this.m03, this.m11, this.m12, this.m13, this.m21, this.m22, this.m23)
            const t31: number = Matrix4.determinant3x3(this.m00, this.m02, this.m03, this.m10, this.m12, this.m13, this.m20, this.m22, this.m23)
            const t32: number = -Matrix4.determinant3x3(this.m00, this.m01, this.m03, this.m10, this.m11, this.m13, this.m20, this.m21, this.m23)
            const t33: number = Matrix4.determinant3x3(this.m00, this.m01, this.m02, this.m10, this.m11, this.m12, this.m20, this.m21, this.m22)
            this.m00 = t00 * determinant_inv
            this.m11 = t11 * determinant_inv
            this.m22 = t22 * determinant_inv
            this.m33 = t33 * determinant_inv
            this.m01 = t10 * determinant_inv
            this.m10 = t01 * determinant_inv
            this.m20 = t02 * determinant_inv
            this.m02 = t20 * determinant_inv
            this.m12 = t21 * determinant_inv
            this.m21 = t12 * determinant_inv
            this.m03 = t30 * determinant_inv
            this.m30 = t03 * determinant_inv
            this.m13 = t31 * determinant_inv
            this.m31 = t13 * determinant_inv
            this.m32 = t23 * determinant_inv
            this.m23 = t32 * determinant_inv
            return true
        }
        return false
    }
}