/**
 * This class contains all key code constants for javascript.
 * 
 * @author Stan Hurks
 */
export class KeyCode {
    public static readonly CANCEL: number = 3
    public static readonly HELP: number = 6
    public static readonly BACK_SPACE: number = 8
    public static readonly TAB: number = 9
    public static readonly CLEAR: number = 12
    public static readonly RETURN: number = 13
    public static readonly ENTER: number = 14
    public static readonly LSHIFT: number = 15
    public static readonly RSHIFT: number = 16
    public static readonly CONTROL: number = 17
    public static readonly ALT: number = 18
    public static readonly PAUSE: number = 19
    public static readonly CAPS_LOCK: number = 20
    public static readonly ESCAPE: number = 27
    public static readonly SPACE: number = 32
    public static readonly PAGE_UP: number = 33
    public static readonly PAGE_DOWN: number = 34
    public static readonly END: number = 35
    public static readonly HOME: number = 36
    public static readonly LEFT: number = 37
    public static readonly UP: number = 38
    public static readonly RIGHT: number = 39
    public static readonly DOWN: number = 40
    public static readonly PRINTSCREEN: number = 44
    public static readonly INSERT: number = 45
    public static readonly DELETE: number = 46
    public static readonly 0: number = 48
    public static readonly 1: number = 49
    public static readonly 2: number = 50
    public static readonly 3: number = 51
    public static readonly 4: number = 52
    public static readonly 5: number = 53
    public static readonly 6: number = 54
    public static readonly 7: number = 55
    public static readonly 8: number = 56
    public static readonly 9: number = 57
    public static readonly SEMICOLON: number = 59
    public static readonly EQUALS: number = 61
    public static readonly A: number = 65
    public static readonly B: number = 66
    public static readonly C: number = 67
    public static readonly D: number = 68
    public static readonly E: number = 69
    public static readonly F: number = 70
    public static readonly G: number = 71
    public static readonly H: number = 72
    public static readonly I: number = 73
    public static readonly J: number = 74
    public static readonly K: number = 75
    public static readonly L: number = 76
    public static readonly M: number = 77
    public static readonly N: number = 78
    public static readonly O: number = 79
    public static readonly P: number = 80
    public static readonly Q: number = 81
    public static readonly R: number = 82
    public static readonly S: number = 83
    public static readonly T: number = 84
    public static readonly U: number = 85
    public static readonly V: number = 86
    public static readonly W: number = 87
    public static readonly X: number = 88
    public static readonly Y: number = 89
    public static readonly Z: number = 90
    public static readonly CONTEXT_MENU: number = 93
    public static readonly NUMPAD0: number = 96
    public static readonly NUMPAD1: number = 97
    public static readonly NUMPAD2: number = 98
    public static readonly NUMPAD3: number = 99
    public static readonly NUMPAD4: number = 100
    public static readonly NUMPAD5: number = 101
    public static readonly NUMPAD6: number = 102
    public static readonly NUMPAD7: number = 103
    public static readonly NUMPAD8: number = 104
    public static readonly NUMPAD9: number = 105
    public static readonly MULTIPLY: number = 106
    public static readonly ADD: number = 107
    public static readonly SEPARATOR: number = 108
    public static readonly SUBTRACT: number = 109
    public static readonly DECIMAL: number = 110
    public static readonly DIVIDE: number = 111
    public static readonly F1: number = 112
    public static readonly F2: number = 113
    public static readonly F3: number = 114
    public static readonly F4: number = 115
    public static readonly F5: number = 116
    public static readonly F6: number = 117
    public static readonly F7: number = 118
    public static readonly F8: number = 119
    public static readonly F9: number = 120
    public static readonly F10: number = 121
    public static readonly F11: number = 122
    public static readonly F12: number = 123
    public static readonly F13: number = 124
    public static readonly F14: number = 125
    public static readonly F15: number = 126
    public static readonly F16: number = 127
    public static readonly F17: number = 128
    public static readonly F18: number = 129
    public static readonly F19: number = 130
    public static readonly F20: number = 131
    public static readonly F21: number = 132
    public static readonly F22: number = 133
    public static readonly F23: number = 134
    public static readonly F24: number = 135
    public static readonly NUM_LOCK: number = 144
    public static readonly SCROLL_LOCK: number = 145
    public static readonly COMMA: number = 188
    public static readonly PERIOD: number = 190
    public static readonly SLASH: number = 191
    public static readonly BACK_QUOTE: number = 192
    public static readonly OPEN_BRACKET: number = 219
    public static readonly BACK_SLASH: number = 220
    public static readonly CLOSE_BRACKET: number = 221
    public static readonly QUOTE: number = 222
    public static readonly META: number = 224
}