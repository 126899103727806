import WebGL from '../../core/WebGL'

/**
 * All framebuffer functionality for the water rendering.
 * 
 * @author Stan Hurks
 */
export default class WaterFrameBuffers {

    /**
     * The width of the reflection frame buffer
     */
    private static readonly REFLECTION_WIDTH: number = 320

    /**
     * The height of the reflection frame buffer
     */
    private static readonly REFLECTION_HEIGHT: number = 180

    /**
     * The width of the refraction frame buffer
     */
    private static readonly REFRACTION_WIDTH: number = 320

    /**
     * The height of the refraction frame buffer
     */
    private static readonly REFRACTION_HEIGHT: number = 180

    /**
     * The texture of the reflection frame buffer
     */
    public reflectionTexture!: WebGLTexture

    /**
     * The refraction texture
     */
    public refractionTexture!: WebGLTexture

    /**
     * The refraction depth texture
     */
    public refractionDepthTexture!: WebGLTexture

    /**
     * The reflection frame buffer
     */
    private reflectionFrameBuffer!: WebGLFramebuffer

    /**
     * The depth buffer for the reflection frame buffer
     */
    private reflectionDepthBuffer!: WebGLRenderbuffer

    /**
     * The refraction frame buffer
     */
    private refractionFrameBuffer!: WebGLFramebuffer

    public constructor () {
        if (WebGL.support.depthTexture && WebGL.support.frameBuffer) {
            this.initializeReflectionFrameBuffer()
            this.initializeRefractionFrameBuffer()
        }
    }

    /**
     * Clean up the frame buffers
     */
    public cleanUp = () => {
        const gl = WebGL.context
        gl.deleteFramebuffer(this.reflectionFrameBuffer)
        gl.deleteTexture(this.reflectionTexture)
        gl.deleteRenderbuffer(this.reflectionDepthBuffer)
        gl.deleteFramebuffer(this.refractionFrameBuffer)
        gl.deleteTexture(this.refractionTexture)
        gl.deleteTexture(this.refractionDepthTexture)
    }

    /**
     * Bind the reflection frame buffer
     */
    public bindReflectionFrameBuffer = () => {
        this.bindFrameBuffer(this.reflectionFrameBuffer, WaterFrameBuffers.REFLECTION_WIDTH, WaterFrameBuffers.REFLECTION_HEIGHT)
    }

    /**
     * Bind the refraction frame buffer
     */
    public bindRefractionFrameBuffer = () => {
        this.bindFrameBuffer(this.refractionFrameBuffer, WaterFrameBuffers.REFRACTION_WIDTH, WaterFrameBuffers.REFRACTION_HEIGHT);
    }

    /**
     * Unbind the frame buffer
     */
    public unbindCurrentFrameBuffer = () => {
        const gl = WebGL.context
        gl.bindFramebuffer(gl.FRAMEBUFFER, null)
        gl.viewport(0, 0, window.innerWidth, window.innerHeight)
    }

    /**
     * Initialize the reflection frame buffer
     */
    public initializeReflectionFrameBuffer = () => {
        this.reflectionFrameBuffer = this.createFrameBuffer()
        this.reflectionTexture = this.createTextureAttachment(WaterFrameBuffers.REFLECTION_WIDTH, WaterFrameBuffers.REFLECTION_HEIGHT)
        this.reflectionDepthBuffer = this.createDepthBufferAttachment(WaterFrameBuffers.REFLECTION_WIDTH, WaterFrameBuffers.REFLECTION_HEIGHT)
        this.unbindCurrentFrameBuffer()
    }

    /**
     * Initialize the refraction frame buffer
     */
    public initializeRefractionFrameBuffer = () => {
        this.refractionFrameBuffer = this.createFrameBuffer()
        this.refractionTexture = this.createTextureAttachment(WaterFrameBuffers.REFRACTION_WIDTH, WaterFrameBuffers.REFRACTION_HEIGHT)
        this.refractionDepthTexture = this.createDepthTextureAttachment(WaterFrameBuffers.REFRACTION_WIDTH, WaterFrameBuffers.REFRACTION_HEIGHT)
        this.unbindCurrentFrameBuffer() 
    }

    /**
     * Bind a frame buffer
     */
    public bindFrameBuffer = (frameBuffer: WebGLFramebuffer, width: number, height: number) => {
        const gl = WebGL.context
        gl.bindTexture(gl.TEXTURE_2D, null)
        gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer)
        gl.viewport(0, 0, width, height)
    }

    /**
     * Create a frame buffer object
     */
    public createFrameBuffer = (): WebGLFramebuffer => {
        const gl = WebGL.context
        const frameBuffer = gl.createFramebuffer()
        if (frameBuffer === null) {
            throw new Error('Could not create framebuffer.')
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer)

        if (WebGL.version === 2) {
            gl["drawBuffers"]([gl.COLOR_ATTACHMENT0])
        }
        else {
            const ext = WebGL.getExtension("draw_buffers")
            ext.drawBuffersWEBGL([
                ext.COLOR_ATTACHMENT0_WEBGL
            ])
        }
        return frameBuffer
    }

    /**
     * Create a texture attachment
     */
    private createTextureAttachment = (width: number, height: number): WebGLTexture => {
        const gl = WebGL.context
        const texture = gl.createTexture()
        if (texture === null) {
            throw new Error('Could not create texture.')
        }
        gl.bindTexture(gl.TEXTURE_2D, texture)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, width, height, 0, gl.RGB, gl.UNSIGNED_BYTE, null)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0)
        return texture
    }

    /**
     * Create a texture attachment for the depth texture
     */
    private createDepthTextureAttachment = (width: number, height: number): WebGLTexture => {
        const gl = WebGL.context
        const texture = gl.createTexture()
        if (texture === null) {
            throw new Error('Could not create texture.')
        }
        gl.bindTexture(gl.TEXTURE_2D, texture);

        if (WebGL.version === 2) {
            gl.texImage2D(gl.TEXTURE_2D, 0, gl["DEPTH_COMPONENT32F"], width, height, 0, gl["DEPTH_COMPONENT"], gl.FLOAT, null)
        }
        else {
            const ext = WebGL.getExtension("depth_texture")
            gl.texImage2D(gl.TEXTURE_2D, 0, gl["DEPTH_COMPONENT32F"], width, height, 0, ext["DEPTH_COMPONENT"], gl.FLOAT, null)
        }

        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, texture, 0)
        return texture
    }

    /**
     * Create a texture attachment for the depth buffer
     */
    private createDepthBufferAttachment = (width: number, height: number): WebGLRenderbuffer => {
        const gl = WebGL.context
        const renderBuffer = gl.createRenderbuffer()
        if (renderBuffer === null) {
            throw new Error('Could not create render buffer.')
        }
        gl.bindRenderbuffer(gl.RENDERBUFFER, renderBuffer)
        gl.renderbufferStorage(gl.RENDERBUFFER, gl["DEPTH_COMPONENT32F"], width, height)
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, renderBuffer)
        return renderBuffer
    } 
}