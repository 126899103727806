import React from 'react'
import Canvas from './engine/core/Canvas'
import './app.scss'
import { Subscription } from 'rxjs'
import Events from './core/Events'
import Mouse from './engine/input/Mouse'
import Vector2 from './engine/math/Vector2'
import Keyboard from './engine/input/Keyboard'
import Assets from './core/Assets'
import AssetLoader from './components/asset-loader/AssetLoader'

/**
 * The state
 */
interface AppState {

    /**
     * The initial list of touches from the touchstart event
     */
    initialTouchList: TouchList|null

    /**
     * The loader percentage
     */
    loaderPercentage: number
}

/**
 * The main app entry point
 * 
 * @author Stan Hurks
 */
export default class App extends React.Component<any, AppState> {

    /**
     * The subscription for the touchstart event on document
     */
    private subscriptionTouchStart!: Subscription

    /**
     * The subscription for the touchmove event on document
     */
    private subscriptionTouchMove!: Subscription

    /**
     * The subscription for the touchend event on document
     */
    private subscriptionTouchEnd!: Subscription

    /**
     * The subscription for the keydown event on document
     */
    private subscriptionKeyDown!: Subscription

    /**
     * The subscription for the keyup event on document
     */
    private subscriptionKeyUp!: Subscription

    /**
     * The subscription for the mousedown event on document
     */
    private subscriptionMouseDown!: Subscription

    /**
     * The subscription for the mouseup event on document
     */
    private subscriptionMouseUp!: Subscription

    /**
     * The subscription for the mousemove event on document
     */
    private subscriptionMouseMove!: Subscription

    /**
     * The subscription for the mousewheel event on document
     */
    private subscriptionMouseWheel!: Subscription

    /**
     * The subscription for the contextmenu event on document
     */
    private subscriptionContextMenu!: Subscription

    /**
     * The subscription for when the loader percentages changes
     */
    private subscriptionLoaderPercentage!: Subscription

    constructor(props: any) {
        super(props)

        this.state = {
            initialTouchList: null,

            loaderPercentage: 100
        }
    }

    public componentDidMount = () => {
        Events.initialize()
        
        this.subscriptionTouchStart = Events.document.touchstart.subscribe((event) => {
            this.setState({
                initialTouchList: event.touches
            })
        })
        this.subscriptionTouchMove = Events.document.touchmove.subscribe((event) => {
            if (this.state.initialTouchList === null) {
                return
            }

            if (event.touches.length === 2 && this.state.initialTouchList.length === 2 && event.changedTouches.length >= 1) {
                
                // The distance at the start
                const distanceStart: number = Math.sqrt(
                    Math.pow(
                        (this.state.initialTouchList.item(1) as Touch).clientX - (this.state.initialTouchList.item(0) as Touch).clientX,
                        2
                    )
                    + Math.pow(
                        (this.state.initialTouchList.item(1) as Touch).clientY - (this.state.initialTouchList.item(0) as Touch).clientY,
                        2
                    )
                )

                // The distance at the end
                const distanceEnd: number = Math.sqrt(
                    Math.pow(
                        (event.touches.item(1) as Touch).clientX - (event.touches.item(0) as Touch).clientX,
                        2
                    )
                    + Math.pow(
                        (event.touches.item(1) as Touch).clientY - (event.touches.item(0) as Touch).clientY,
                        2
                    )
                )

                // Set the scroll delta
                Mouse.scrollDeltaY = -(distanceEnd - distanceStart) * 20
            } else if (event.touches.length === 1 && this.state.initialTouchList.length === 1 && event.changedTouches.length === 1) {

                // The start position
                const positionStart: Vector2 = new Vector2(
                    (this.state.initialTouchList.item(0) as Touch).clientX,
                    (this.state.initialTouchList.item(0) as Touch).clientY,
                )

                // The end position
                const positionEnd: Vector2 = new Vector2(
                    (this.state.initialTouchList.item(0) as Touch).clientX,
                    (this.state.initialTouchList.item(0) as Touch).clientY,
                )

                // Set the delta
                Mouse.mobileDeltaX = positionEnd.x - positionStart.x
                Mouse.mobileDeltaY = positionEnd.y - positionStart.y
            }

            // Otherwise prevent the event
            event.preventDefault()
            return false
        })
        this.subscriptionTouchEnd = Events.document.touchend.subscribe((event) => {
            this.setState({
                initialTouchList: null
            })
            event.preventDefault()
            return false
        })
        this.subscriptionKeyDown = Events.document.keydown.subscribe((event) => {
            Keyboard.addKeyDown(event.keyCode)
            event.preventDefault()
            return false
        })
        this.subscriptionKeyUp = Events.document.keyup.subscribe((event) => {
            Keyboard.removeKeyDown(event.keyCode)
            Keyboard.addKeyReleased(event.keyCode)
            event.preventDefault()
            return false
        })
        this.subscriptionMouseDown = Events.document.mousedown.subscribe((event) => {
            Mouse.addDown(event.button)
            event.preventDefault()
            return false
        })
        this.subscriptionMouseUp = Events.document.mouseup.subscribe((event) => {
            Mouse.removeDown(event.button)
            event.preventDefault()
            return false
        })
        this.subscriptionMouseMove = Events.document.mousemove.subscribe((event) => {
            Mouse.setPosition(new Vector2(event.clientX, event.clientY))
            event.preventDefault()
            return false
        })
        this.subscriptionMouseWheel = Events.document.mousewheel.subscribe((event) => {
            Mouse.scrollDeltaX = (event as any).wheelDeltaX
            Mouse.scrollDeltaY = (event as any).wheelDeltaY
            return false
        })
        this.subscriptionContextMenu = Events.document.contextmenu.subscribe((event) => {
            event.preventDefault()
            return false
        })
        this.subscriptionLoaderPercentage = Assets.loadPercentageChange.subscribe((loaderPercentage) => {
            this.setState({
                loaderPercentage
            })
        })
    }

    public componentWillUnmount = () => {
        this.subscriptionTouchStart.unsubscribe()
        this.subscriptionTouchMove.unsubscribe()
        this.subscriptionTouchEnd.unsubscribe()
        this.subscriptionKeyDown.unsubscribe()
        this.subscriptionKeyUp.unsubscribe()
        this.subscriptionMouseDown.unsubscribe()
        this.subscriptionMouseUp.unsubscribe()
        this.subscriptionMouseMove.unsubscribe()
        this.subscriptionMouseWheel.unsubscribe()
        this.subscriptionContextMenu.unsubscribe()
        this.subscriptionLoaderPercentage.unsubscribe()
    }

    public render = () => {
        return (
            <div id="app">
                {
                    this.state.loaderPercentage !== 100
                    &&
                    <AssetLoader percentage={this.state.loaderPercentage} />
                }

                <Canvas />
            </div>
        )
    }
}