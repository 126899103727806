import Matrix4 from './Matrix4'
import Maths from '../core/Maths'
import Transform from './Transform'
import Vector3 from './Vector3'
import Vector2 from './Vector2'
import Camera from '../game-object/Camera'

/**
 * All matrices related functionality.
 * 
 * @author Stan Hurks
 */
export default class Matrices {

    /**
     * The field of view used for the projection matrix
     */
    public static readonly PROJECTION_MATRIX_FIELD_OF_VIEW: number = 70

    /**
     * The near plane used for the projection matrix
     */
    public static readonly PROJECTION_MATRIX_NEAR_PLANE: number = 0.1

    /**
     * The far plane used for the projection matrix
     */
    public static readonly PROJECTION_MATRIX_FAR_PLANE: number = 1000

    /**
     * Create a projection matrix
     */
    public static createProjectionMatrix = (): Matrix4 => {
        const aspectRatio : number = window.innerWidth / window.innerHeight
        const yScale : number = (1 / Math.tan(Maths.toRadians(Matrices.PROJECTION_MATRIX_FIELD_OF_VIEW / 2)) * aspectRatio)
        const xScale : number = yScale / aspectRatio
        const frustumLength : number = Matrices.PROJECTION_MATRIX_FAR_PLANE - Matrices.PROJECTION_MATRIX_NEAR_PLANE

        const matrix : Matrix4 = new Matrix4()
        matrix.m00 = xScale
        matrix.m11 = yScale
        matrix.m22 = - ((Matrices.PROJECTION_MATRIX_FAR_PLANE + Matrices.PROJECTION_MATRIX_NEAR_PLANE) / frustumLength)
        matrix.m23 = -1
        matrix.m32 = - ((2 * Matrices.PROJECTION_MATRIX_NEAR_PLANE * Matrices.PROJECTION_MATRIX_FAR_PLANE) / frustumLength)
        matrix.m33 = 0

        return matrix
    }

    /**
     * Create a transformation matrix for a transform
     */
    public static createTransformationMatrix = (transform: Transform): Matrix4 => {
        const matrix : Matrix4 = new Matrix4()
        matrix.setIdentity()
        matrix.translate(transform.position)
        matrix.rotate(Maths.toRadians(transform.rotation.x), new Vector3(1,0,0))
        matrix.rotate(Maths.toRadians(transform.rotation.y), new Vector3(0,1,0))
        matrix.rotate(Maths.toRadians(transform.rotation.z), new Vector3(0,0,1))
        matrix.scale(transform.scale)
        return matrix
    }

    /**
     * Create a transformation matrix for a quad
     */
    public static createTransformationMatrixForQuad = (position: Vector2, scale: Vector2): Matrix4 => {
        const matrix : Matrix4 = new Matrix4()
        matrix.setIdentity()
        matrix.translate(new Vector3(position.x, position.y, 0))
        matrix.scale(new Vector3(scale.x, scale.y, 1))
        return matrix
    }

    /**
     * Create a view matrix based on the camera
     */
    public static createViewMatrix = (camera: Camera): Matrix4 => {
        const matrix : Matrix4 = new Matrix4()
        matrix.setIdentity()
        matrix.rotate(Maths.toRadians(camera.pitch), new Vector3(1, 0, 0))
        matrix.rotate(Maths.toRadians(camera.yaw), new Vector3(0, 1, 0))
        
        const cameraPosition : Vector3 = camera.position
        const negativeCameraPosition : Vector3 = new Vector3(-cameraPosition.x, -cameraPosition.y, -cameraPosition.z)
        matrix.translate(negativeCameraPosition)
        return matrix
    }
}