import GameObjectLight from './GameObjectLight'
import Transform from '../math/Transform'
import GameObjectModel from './GameObjectModel'

/**
 * An object in the game.
 * 
 * @author Stan Hurks
 */
export default class GameObject {

    /**
     * The ID given to this game object
     */
    public id?: string

    /**
     * The model of the game object
     */
    public model: GameObjectModel

    /**
     * The transform properties of the game object
     */
    public transform: Transform

    /**
     * The light that follows the game object
     */
    public light?: GameObjectLight

    /**
     * The names of components that are hidden
     */
    public hiddenComponentNames: string[] = []

    constructor(model: GameObjectModel, transform: Transform, light?: GameObjectLight) {
        this.model = model
        this.transform = transform
        this.light = light
    }

    /**
     * Get the x offset for the texture sprite
     */
    public getTextureXOffset = (): number => {
        if (this.model.texture.sprite) {
            const column: number = this.model.texture.sprite.currentIndex % this.model.texture.sprite.rows
            return column / this.model.texture.sprite.rows
        }
        return 0
    }

    /**
     * Get the y offset for the texture sprite
     */
    public getTextureYOffset = (): number => {
        if (this.model.texture.sprite) {
            const row: number = this.model.texture.sprite.currentIndex / this.model.texture.sprite.rows
            return row / this.model.texture.sprite.rows
        }
        return 0
    }

    /**
     * Increases the position for an entity.
     */
    public increasePosition = (dx: number, dy: number, dz: number) => {
        this.transform.position.x += dx
        this.transform.position.y += dy
        this.transform.position.z += dz
    }

    /**
     * Increases the rotation for an entity.
     */
    public increaseRotation = (dx: number, dy: number, dz: number) => {
        this.transform.rotation.x += dx
        this.transform.rotation.y += dy
        this.transform.rotation.z += dz
    }
}