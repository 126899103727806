import Vector4 from '../../math/Vector4'
import WebGL from '../../core/WebGL'
import Assets from '../../../core/Assets'
import GameObjectModelComponent from '../../game-object/GameObjectModelComponent'
import Vector3 from '../../math/Vector3'
import Environment from '../../core/Environment'

/**
 * This class will handle loading models/textures to the VAO.
 * 
 * @author Stan Hurks
 */
export default class Loader {

    /**
     * The default texture color
     */
    public static readonly defaultTextureColor: Vector4 = new Vector4(0, 0, 255, 255)

    /**
     * The buffers used in the game
     */
    private static buffers: WebGLBuffer[] = []

    /**
     * All loaded textures mapped by name
     */
    private static textures: {[textureName: string]: WebGLTexture} = {}

    /**
     * Load positions to the VAO.
     */
    public static loadPositionsToVao = (vertices: number[], dimensions: number): GameObjectModelComponent => {
        const gl = WebGL.context
        const arrayBuffer = gl.createBuffer()
        if (arrayBuffer === null) {
            throw new Error('Could not create array buffer.')
        }

        Loader.buffers.push(arrayBuffer)
        gl.bindBuffer(gl.ARRAY_BUFFER, arrayBuffer)
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW)
        gl.bindBuffer(gl.ARRAY_BUFFER, null)

        return {
            arrayBuffer,
            indicesCount: vertices.length / dimensions
        }
    }

    /**
     * Load positions to the vao
     */
    public static loadPositionsAndNormalsToVao = (vertices: number[], normals: number[], dimensions: number): GameObjectModelComponent => {
        const gl: WebGLRenderingContext = WebGL.context
        const arrayBuffer = gl.createBuffer()
        if (arrayBuffer === null) {
            throw new Error('Could not create array buffer.')
        }
       
        Loader.buffers.push(arrayBuffer);

        const totalBuffer: number[] = []
        for (let i : number = 0; i < totalBuffer.length; i ++) {
            for (let j : number = 0; j < 3; j ++) {
                if (vertices.length >= i * 3 + j) {
                    totalBuffer[i * 6 + j] = vertices[i * 3 + j]
                }
            }

            for (let j : number = 0; j < 3; j ++) {
                if (normals.length >= i * 3 + j) {
                    totalBuffer[i * 6 + j + 3] = normals[i * 3 + j]
                }
            }
        }

        gl.bindBuffer(gl.ARRAY_BUFFER, arrayBuffer)
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(totalBuffer), gl.STATIC_DRAW)
        gl.bindBuffer(gl.ARRAY_BUFFER, null)

        return {
            arrayBuffer,
            indicesCount: vertices.length / dimensions
        }
    }

    /**
     * Load a model to the vao
     */
    public static loadToVao = (vertices: number[], indices: number[], textureCoordinates: number[], normals: number[]): GameObjectModelComponent => {
        var gl = WebGL.context

        let elementArrayBuffer: WebGLBuffer|null = null
        if (indices.length > 0) {
            elementArrayBuffer = gl.createBuffer()
            if (elementArrayBuffer === null) {
                throw new Error('Could not create element array buffer.')
            }

            Loader.buffers.push(elementArrayBuffer)
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, elementArrayBuffer)
            gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Int16Array(indices), gl.STATIC_DRAW)
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null)
        }

        const arrayBuffer = gl.createBuffer()
        if (arrayBuffer === null) {
            throw new Error('Could not create array buffer.')
        }

        Loader.buffers.push(arrayBuffer)
        gl.bindBuffer(gl.ARRAY_BUFFER, arrayBuffer)
        const totalBuffer : number[] = Array.from({length : vertices.length * 2 + (vertices.length * 2/3)}).map(() => 0)
        for (let i = 0; i < totalBuffer.length / (3 + 2 + 3); i ++) {
            for (let j = 0; j < 3; j ++) {
                if (vertices.length > i * 3 + j) {
                    totalBuffer[i * 8 + j] = vertices[i * 3 + j]
                }
            }
            for (let j = 0; j < 2; j ++) {
                if (textureCoordinates.length > i * 2 + j) {
                    totalBuffer[i * 8 + 3 + j] = textureCoordinates[i * 2 + j]
                }
            }
            for (let j = 0; j < 3; j ++) {
                if (normals.length >= i * 3 + j) {
                    totalBuffer[i * 8 + 5 + j] = normals[i * 3 + j]
                }
            }
        }

        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(totalBuffer), gl.STATIC_DRAW)
        gl.bindBuffer(gl.ARRAY_BUFFER, null)
        gl.bindTexture(gl.TEXTURE_2D, null)

        return {
            arrayBuffer,
            elementArrayBuffer: elementArrayBuffer || undefined,
            indicesCount: indices.length === 0 ? vertices.length / 3 : indices.length
        }
    }

    /**
     * Load color as texture
     */
    public static loadColorAsTexture = (color: Vector4): WebGLTexture => {
        const gl = WebGL.context
        const texture = gl.createTexture()
        if (texture === null) {
            throw new Error('Could not create texture.')
        }

        gl.bindTexture(gl.TEXTURE_2D, texture)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE,
            new Uint8Array([color.x, color.y, color.z, color.w]))
        gl.bindTexture(gl.TEXTURE_2D, null)
        return texture
    }

    /**
     * Loads the default color as texture to WebGL until the actual image is loaded async.
     */
    public static loadTexture = (textureName: string, repeatImage: boolean, color?: Vector4): WebGLTexture => {
        const textureLocation = `${textureName}.png`
        const textureColor = color || Loader.defaultTextureColor

        if (Loader.textures[textureName] !== undefined) {
            return Loader.textures[textureName]
        }

        const gl = WebGL.context
        const texture = gl.createTexture()
        if (texture === null) {
            throw new Error(`Could not load texture: ${textureLocation}`)
        }

        // Bind a color to the texture
        gl.bindTexture(gl.TEXTURE_2D, texture)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE,
            new Uint8Array([textureColor.x, textureColor.y, textureColor.z, textureColor.w]))
        gl.bindTexture(gl.TEXTURE_2D, null)

        // Add the texture to the map of textures
        Loader.textures[textureName] = texture

        // Load the texture
        Assets.load(textureLocation).then((base64) => {            
            const image = new Image()
            image.src = `data:image/png;base64,${base64}`
            image.addEventListener('load', () => {
                const useMipmap = WebGL.version === 2 && (image.height & (image.height - 1)) === 0 && (image.width & (image.width - 1)) === 0 && image.height === image.width

                gl.bindTexture(gl.TEXTURE_2D, texture)
    
                if (repeatImage) {
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT)
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT)
                }
                else {
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
                }

                if (useMipmap) {
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR)
                } else {
                    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
                }
                gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
    
                gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image)

                // Generate a mipmap when there is support
                if (useMipmap) {
                    gl.generateMipmap(gl.TEXTURE_2D)
                }

                gl.bindTexture(gl.TEXTURE_2D, null)
            })
        }).catch(() => {
            throw new Error(`Could not load texture: ${textureLocation}`)
        })

        return texture
    }

    /**
     * Load a cube map
     */
    public static loadCubeMap = (textureFiles : string[]): WebGLTexture => {
        const gl: WebGLRenderingContext = WebGL.context
        const texture: WebGLTexture|null = gl.createTexture()

        if (texture === null) {
            throw new Error('Could not create texture.')
        }

        gl.activeTexture(gl.TEXTURE0)
        gl.bindTexture(gl.TEXTURE_CUBE_MAP, texture)

        const skyColor: Vector3 = Environment.getCurrentSkyColor()
        for (let i: number = 0; i < 6; i ++) {
            gl.texImage2D(
                gl.TEXTURE_CUBE_MAP_POSITIVE_X + i,
                0,
                gl.RGBA,
                1,
                1,
                0,
                gl.RGBA,
                gl.UNSIGNED_BYTE,
                new Uint8Array([skyColor.x, skyColor.y, skyColor.z, 255])
            )
        }

        gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
        gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
        gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
        gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
        gl.generateMipmap(gl.TEXTURE_CUBE_MAP)

        gl.bindTexture(gl.TEXTURE_CUBE_MAP, null)

        Loader.loadCubeMapTexture(texture, textureFiles, [])

        return texture
    }

    /**
     * Clean up the loader
     */
    public static cleanUp = () => {
        const gl = WebGL.context

        for (const buffer of Loader.buffers) {
            gl.deleteBuffer(buffer)
        }
    }

    /**
     * Load a cube map texture.
     */
    private static loadCubeMapTexture = (texture: WebGLTexture, textureFiles: string[], images: ImageData[]) => {
        const gl = WebGL.context
        
        Assets.load(`textures/cubemaps/${textureFiles[images.length]}.png`).then((base64) => {
            const image = new Image()
            image.src = `data:image/png;base64,${base64}`
            image.addEventListener('load', () => {
                
                const canvas = document.createElement('canvas')
                canvas.height = image.height
                canvas.width = image.width

                const context = canvas.getContext('2d')
                if (context === null) {
                    throw new Error(`No 2D context in canvas for texture: ${textureFiles[images.length]}.`)
                }

                context.drawImage(image, 0, 0, canvas.width, canvas.height)
                images.push(context.getImageData(0, 0, canvas.width, canvas.height))

                // Wait for all images to be done loading
                if (images.length < 6) {
                    Loader.loadCubeMapTexture(texture, textureFiles, images)
                    return
                }

                // Bind the texture to WebGL
                gl.activeTexture(gl.TEXTURE0);
                gl.bindTexture(gl.TEXTURE_CUBE_MAP, texture);
                for (let i : number = 0; i < 6; i ++) {
                    gl.texImage2D(gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, images[i]);
                }
                gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
                gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
                gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
                gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
                gl.generateMipmap(gl.TEXTURE_CUBE_MAP);
                gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);
            })
        }).catch(() => {
            throw new Error(`Texture not found: ${textureFiles[images.length]}`)
        })
    }
}