import React from 'react'
import './asset-loader.scss'
import RadialProgress from '../radial-progress/RadialProgress';

/**
 * The props
 */
interface AssetLoaderProps {

    /**
     * The percentage of the asset loader
     */
    percentage: number
}

/**
 * The asset loader.
 * 
 * @author Stan Hurks
 */
export default class AssetLoader extends React.Component<AssetLoaderProps> {

    public render = () => {
        return (
            <div className="asset-loader">
                <div className="asset-loader-content">
                    <RadialProgress
                        percentage={this.props.percentage}
                        />
                </div>
            </div>
        )
    }
}