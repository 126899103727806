/**
 * A three dimensional vector.
 * 
 * @author Stan Hurks
 */
export default class Vector3 {

    public x: number

    public y: number

    public z: number

    constructor(x: number, y: number, z: number) {
        this.x = x
        this.y = y
        this.z = z
    }

    /**
     * Calculate the distance between two vectors.
     */
    public static distance = (a: Vector3, b: Vector3): number => {
        return Math.sqrt(
            Math.pow((a.x - b.x), 2) +
            Math.pow((a.y - b.y), 2) +
            Math.pow((a.z - b.z), 2)
        )
    }

    /**
     * Calculate the cross product for 2 vectors
     */
    public static cross (left: Vector3, right: Vector3): Vector3 {
        return new Vector3(
            left.y * right.z - left.z * right.y,
            right.x * left.z - right.z * left.x,
            left.x * right.y - left.y * right.x
        )
    }

    /**
     * Get the squared length of this vector
     */
    public lengthSquared = (): number => {
        return Math.pow(this.x, 2)
            + Math.pow(this.y, 2)
            + Math.pow(this.z, 2)
    }

    /**
     * Scale this vector
     */
    public scale = (scale: number) => {
        this.x *= scale
        this.y *= scale
        this.z *= scale
    }

    /**
     * Normalise this vector
     */
    public normalise = () => {
        const length = Math.sqrt(this.lengthSquared())
        if (length !== 0) {
            this.scale(1.0 / length)
        }
    }
}