import { Subject } from 'rxjs'

/**
 * All assets related functionality.
 * 
 * @author Stan Hurks
 */
export default class Assets {

    /**
     * Whenever the URL of the asset is not reachable
     */
    public static readonly notReachable: Subject<void> = new Subject()

    /**
     * Whenever the server has an error
     */
    public static readonly httpStatusInternalServerError: Subject<void> = new Subject()

    /**
     * Whenever the load percentage changes
     */
    public static readonly loadPercentageChange: Subject<number> = new Subject()

    /**
     * The load status for assets
     */
    private static assetsLoaded: {[assetName: string]: {
        bytes: {
            total: number
            loaded: number
        }
    }} = {}

    /**
     * Get the load percentage of all yet to be loaded assets
     */
    public static getLoadPercentage = (): number => {
        let loadedBytes = 0
        let totalBytes = 0
        for (const key of Object.keys(Assets.assetsLoaded)) {
            const asset = Assets.assetsLoaded[key]
            if (asset.bytes.loaded !== asset.bytes.total) {
                loadedBytes += asset.bytes.loaded
                totalBytes += asset.bytes.total
            }
        }
        return totalBytes === 0
            ? 100
            : loadedBytes / totalBytes * 100
    }

    /**
     * Makes a XMLHttpRequest and loads an asset
     * @param assetName the name of the asset
     */
    public static load = (assetName: string): Promise<string> => {
        const assetLocation = `/assets/${assetName}`

        return new Promise((resolve, reject) => {
            const performRequest = (cache?: Cache) => {
                Assets.assetsLoaded[assetName] = {
                    bytes: {
                        total: 1,
                        loaded: 0
                    }
                }

                const request = new XMLHttpRequest()
                request.open('GET', assetLocation)
                request.setRequestHeader('Content-Type', 'application/json')

                if (assetLocation.endsWith('.png')) {
                    request.responseType = 'arraybuffer'
                }
    
                request.onprogress = (event: any) => {
                    Assets.assetsLoaded[assetName] = {
                        bytes: {
                            total: event.total,
                            loaded: event.loaded
                        }
                    }
                    Assets.loadPercentageChange.next(Assets.getLoadPercentage())
                }

                request.onload = () => {
                    delete Assets.assetsLoaded[assetName]

                    const headersArray = request.getAllResponseHeaders()
                        .split(/[\r\n]+/)
                        .filter((v) => v.length)
                    
                    const headers = {}
                    for (const headersArrayPart of headersArray) { 
                        const headersArrayParts = headersArrayPart.split(':').map((v) => v.trim())
                        headers[headersArrayParts[0].toLowerCase()] = headersArrayParts[1]
                    }
    
                    if (request.status < 400) {
                        if (assetLocation.endsWith('.png')) {
                            
                            // Convert to blob
                            const uInt8Array = new Uint8Array(request.response)
                            let index = uInt8Array.length
                            let binaryString = new Array(index)
                            while (index--) {
                                binaryString[index] = String.fromCharCode(uInt8Array[index])
                            }
                            const data = binaryString.join('')
                            const base64 = window.btoa(data)

                            // Put in cache
                            if (cache) {
                                cache.put(assetLocation, new Response(base64))
                            }

                            resolve(base64)
                        } else if (cache) {
                            cache.put(assetLocation, new Response(request.response))
                        }

                        resolve(request.response)
                    } else {
                        if (request.status === 500) {
                            Assets.httpStatusInternalServerError.next()
                        }
    
                        let response: any
                        try {
                            response = JSON.parse(request.response)
                        } catch (e) {
                            response = request.response
                        }
    
                        // eslint-disable-next-line
                        reject({
                            data: response,
                            status: request.status,
                            headers
                        })
                    }
                }
                request.onerror = () => {
                    delete Assets.assetsLoaded[assetName]

                    Assets.notReachable.next()
    
                    reject(request.statusText)
                }
                request.send()
            }

            if (!assetLocation.endsWith('.glsl')
                && !assetLocation.endsWith('.json')) {
                caches.open('assets').then((cache) => {
                    cache.match(assetLocation).then((matchResponse) => {
                        
                        // Return the asset from cache
                        if (matchResponse) {
                            delete Assets.assetsLoaded[assetName]
    
                            resolve(matchResponse.text())
                        }
                        
                        // Otherwise fetch the request
                        else {
                            performRequest(cache)
                        }
                    })
                })
            } else {

                // Perform the request
                performRequest()
            }
        })
    }
}