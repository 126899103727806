import WebGL from './WebGL'
import Vector3 from '../math/Vector3'
import Canvas from './Canvas'

/**
 * The environment of the game
 * 
 * @author Stan Hurks
 */
export default class Environment {

    private static readonly SECONDS_PER_DAY_CYCLE: number = 60

    private static skyColorDay: Vector3 = new Vector3(105 / 255, 175 / 255, 255 / 255)

    private static skyColorDawn: Vector3 = new Vector3(255 / 255, 173 / 255, 153 / 255)

    private static skyColorNight: Vector3 = new Vector3(105 / 255 / 10, 175 / 255 / 10, 255 / 255 / 10)

    private static hour: number = 10

    private static _fogAmount: number = 2

    /**
     * Get the fog amount
     */
    public static get fogAmount() : number {
        let add: number = 0
        if (WebGL.version === 2) {
            add = 2
        }
        return Environment._fogAmount + (5 * (1 - Environment.getDayLightValue())) + add
    }

    /**
     * Set the fog amount
     */
    public static set fogAmount(fogAmount: number) {
        Environment._fogAmount = fogAmount
    }

    /**
     * Get the current color of the sun
     */
    public static getSunColor = (): Vector3 => {
        const currentSkyColor: Vector3 = Environment.getCurrentSkyColor()
        const dayLightValue: number = Environment.getDayLightValue()
        const minAddition: number = 0.25 * (1 - dayLightValue)
        return new Vector3(
            currentSkyColor.x * 0.50 + minAddition,
            currentSkyColor.y * 0.50 + minAddition,
            currentSkyColor.z * 0.50 + minAddition
        )
    }

    /**
     * Get the current position of the sun
     */
    public static getSunPosition = (): Vector3 => {
        return new Vector3(
            -1000 + 2000 * (Environment.hour / 24),
            1000 + (Environment.hour / 24) * 1000,
            -1000 + 2000 * (Environment.hour / 24)
        )
    }

    /**
     * Get the day light value for dimming the lights in the environment.
     */
    public static getDayLightValue = (): number => {
        if (Environment.hour > 0 && Environment.hour <= 7) {
            return 0
        }
        else if (Environment.hour > 7 && Environment.hour <= 8) {
            return 1 - (8 - Environment.hour)
        }
        else if (Environment.hour > 8 && Environment.hour <= 23) {
            return 1
        }
        else if (Environment.hour > 23 && Environment.hour <= 24) {
            return 1 - (1 - (24 - Environment.hour))
        }
        return 0
    }

    /**
     * Get the day factor based on the hour
     */
    public static getDayFactor = (): number => {
        if (Environment.hour > 7 && Environment.hour <= 8) {
            return 1 - (8 - Environment.hour)
        }
        else if (Environment.hour > 8 && Environment.hour <= 17) {
            return 1
        }
        else if (Environment.hour > 17 && Environment.hour <= 18) {
            return 1 - (1 - (18 - Environment.hour))
        }
        return 0
    }

    /**
     * Get the dawn factor based on the hour
     */
    public static getDawnFactor = (): number => {
        if (Environment.hour > 17 && Environment.hour <= 18) {
            return 1 - (18 - Environment.hour)
        }
        else if (Environment.hour > 18 && Environment.hour <= 23) {
            return 1
        }
        else if (Environment.hour > 23 && Environment.hour <= 24) {
            return 1 - (1 - (24 - Environment.hour))
        }
        return 0
    }

    /**
     * Get the night factor based on the hour
     */
    public static getNightFactor = (): number => {
        if (Environment.hour > 0 && Environment.hour <= 7) {
            return 1
        }
        else if (Environment.hour > 7 && Environment.hour <= 8) {
            return 1 - (1 - (8 - Environment.hour))
        }
        else if (Environment.hour > 23 && Environment.hour <= 24) {
            return 1 - (24 - Environment.hour)
        }
        return 0
    }

    /**
     * Get the current sky color
     */
    public static getCurrentSkyColor = (): Vector3 => {
        //between 8-18
        const dayFactor: number = Environment.getDayFactor()
        
        //between 18-24
        const dawnFactor: number = Environment.getDawnFactor()

        //between 0-8
        const nightFactor: number = Environment.getNightFactor()

        return new Vector3(
            (Environment.skyColorDay.x * dayFactor) + (Environment.skyColorDawn.x * dawnFactor) + (Environment.skyColorNight.x * nightFactor),
            (Environment.skyColorDay.y * dayFactor) + (Environment.skyColorDawn.y * dawnFactor) + (Environment.skyColorNight.y * nightFactor),
            (Environment.skyColorDay.z * dayFactor) + (Environment.skyColorDawn.z * dawnFactor) + (Environment.skyColorNight.z * nightFactor)
        )
    }

    /**
     * Updates the environment
     */
    public static update = () => {
        Environment.hour += Canvas.deltaTimeMS / 1000 / Environment.SECONDS_PER_DAY_CYCLE * 24
        if (Environment.hour > 24) {
            Environment.hour -= 24
        }
    }
}