/**
 * A 2 dimensional vector.
 * 
 * @author Stan Hurks
 */
export default class Vector2 {

    public x: number

    public y: number

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
    }

    /**
     * Calculate the distance between two vectors.
     */
    public static distance = (a: Vector2, b: Vector2) => {
        return Math.sqrt(
            Math.pow((a.x - b.x), 2) +
            Math.pow((a.y - b.y), 2)
        )
    }
}