import {
    fromEvent, Subject, Observable
} from 'rxjs'
import Browser from './Browser'

/**
 * In here all rxjs events for commonly used events.
 * 
 * As there only needs to be one event registered for each commonly used event
 * this will save the amount of event listeners used in the application.
 * 
 * Due to the `cleanUp` method this class will also unregister everything without
 * having to do so manually, every time.
 * 
 * @author Stan Hurks
 */
export default class Events {

    /**
     * All window events
     */
    public static window = {
        resize: fromEvent(window, 'resize'),
        orientation: fromEvent(window, 'orientationchange')
    }

    /**
     * All document events
     */
    public static document: {
        click: Observable<Event>,
        keyup: Observable<KeyboardEvent>,
        keydown: Observable<KeyboardEvent>,
        keypress: Observable<KeyboardEvent>,
        mouseup: Subject<MouseEvent>,
        mousedown: Subject<MouseEvent>,
        mousemove: Subject<MouseEvent>,
        mousewheel: Observable<MouseWheelEvent>,
        touchstart: Subject<TouchEvent>,
        touchmove: Subject<TouchEvent>,
        touchend: Subject<TouchEvent>,
        submit: Observable<Event>,
        contextmenu: Observable<Event>
    } = {
        click: fromEvent(document, 'click'),
        keyup: fromEvent(document, 'keyup') as any,
        keydown: fromEvent(document, 'keydown') as any,
        keypress: fromEvent(document, 'keypress') as any,
        mouseup: new Subject(),
        mousemove: new Subject(),
        mousedown: new Subject(),
        mousewheel: fromEvent(document, 'mousewheel') as any,
        touchstart: new Subject(),
        touchmove: new Subject(),
        touchend: new Subject(),
        submit: fromEvent(document, 'submit'),
        contextmenu: fromEvent(document, 'contextmenu')
    }

    /**
     * Initialize the Events
     */
    public static initialize = () => {
        document.addEventListener('mouseup', Events.onDocumentMouseup as any, Browser.passiveOption ? { passive: false } : false)
        document.addEventListener('mousemove', Events.onDocumentMousemove as any, Browser.passiveOption ? { passive: false } : false)
        document.addEventListener('mousedown', Events.onDocumentMousedown as any, Browser.passiveOption ? { passive: false } : false)
        document.addEventListener('touchstart', Events.onDocumentTouchstart as any, Browser.passiveOption ? { passive: false } : false)
        document.addEventListener('touchmove', Events.onDocumentTouchmove as any, Browser.passiveOption ? { passive: false } : false)
        document.addEventListener('touchend', Events.onDocumentTouchend as any, Browser.passiveOption ? { passive: false } : false)
    }

    /**
     * Clean up the Events
     */
    public static cleanUp = () => {
        document.removeEventListener('mouseup', Events.onDocumentMouseup as any)
        document.removeEventListener('mousemove', Events.onDocumentMousemove as any)
        document.removeEventListener('mousedown', Events.onDocumentMousedown as any)
        document.removeEventListener('touchstart', Events.onDocumentTouchstart as any)
        document.removeEventListener('touchmove', Events.onDocumentTouchmove as any)
        document.removeEventListener('touchend', Events.onDocumentTouchend as any)
    }

    /**
     * Whenever the Mouseup event occurs in the document
     */
    private static onDocumentMouseup = (event: MouseEvent) => {
        Events.document.mouseup.next(event)
    }

    /**
     * Whenever the Mousemove event occurs in the document
     */
    private static onDocumentMousemove = (event: MouseEvent) => {
        Events.document.mousemove.next(event)
    }

    /**
     * Whenever the Mousedown event occurs in the document
     */
    private static onDocumentMousedown = (event: MouseEvent) => {
        Events.document.mousedown.next(event)
    }

    /**
     * Whenever the Touchstart event occurs in the document
     */
    private static onDocumentTouchstart = (event: TouchEvent) => {
        Events.document.touchstart.next(event)
    }

    /**
     * Whenever the Touchmove event occurs in the document
     */
    private static onDocumentTouchmove = (event: TouchEvent) => {
        Events.document.touchmove.next(event)
    }

    /**
     * Whenever the Touchend event occurs in the document
     */
    private static onDocumentTouchend = (event: TouchEvent) => {
        Events.document.touchend.next(event)
    }
}