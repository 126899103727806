import Vector2 from '../math/Vector2'

/**
 * All mouse functionality.
 * 
 * @author Stan Hurks
 */
export default class Mouse {

    /**
     * The minimum distance to record a swipe movement on mobile
     */
    public static readonly minSwipeDistance: number = 0.5

    /**
     * The delta x coordinate of a swipe on mobile
     */
    public static mobileDeltaX: number = 0

    /**
     * The delta y coordinate of a swipe on mobile
     */
    public static mobileDeltaY: number = 0

    /**
     * The delta x coordinate
     */
    public static deltaX: number = 0

    /**
     * The delta y coordinate
     */
    public static deltaY: number = 0

    /**
     * The horizontal delta of a scroll
     */
    public static scrollDeltaX: number = 0

    /**
     * The vertical delta of a scroll
     */
    public static scrollDeltaY: number = 0

    /**
     * The mouse buttons that are down
     */
    public static buttonsDown: number[] = []

    /**
     * The mouse buttons that are released
     */
    public static buttonsReleased: number[] = []

    /**
     * The previous mouse position
     */
    public static previousPosition: Vector2|null = null

    /**
     * Get the mouse position
     */
    public static getPosition = (): Vector2 => {
        return Mouse.previousPosition == null
            ? new Vector2(0, 0)
            : Mouse.previousPosition
    }

    /**
     * Set the current mouse position
     */
    public static setPosition = (mousePosition: Vector2) => {
        if (Mouse.previousPosition == null) {
            Mouse.deltaX = 0
            Mouse.deltaY = 0
        }
        else {
            Mouse.deltaX = mousePosition.x - Mouse.previousPosition.x
            Mouse.deltaY = mousePosition.y - Mouse.previousPosition.y
        }
        Mouse.previousPosition = mousePosition
    }

    /**
     * Clear released list
     */
    public static resetReleased = () => {
        Mouse.buttonsReleased = []
    }

    /**
     * Check if a mouse key is released
     */
    public static isReleased = (mouseKey: number): boolean => {
        return Mouse.buttonsReleased.indexOf(mouseKey) !== -1
    }

    /**
     * Add a released mouse key
     */
    public static addReleased = (mouseKey: number) => {
        if (!Mouse.isReleased(mouseKey)) {
            Mouse.buttonsReleased.push(mouseKey)
        }
    }

    /**
     * Remove a released mouse key
     */
    public static removeReleased = (mouseKey: number) => {
        if (Mouse.isReleased(mouseKey)) {
            Mouse.buttonsReleased.splice(Mouse.buttonsReleased.indexOf(mouseKey), 1)
        }
    }

    /**
     * Check if a mouse key is down
     */
    public static isDown = (mouseKey: number): boolean => {
        return Mouse.buttonsDown.indexOf(mouseKey) !== -1
    }

    /**
     * Add a down mouse key
     */
    public static addDown = (mouseKey: number) => {
        if (!Mouse.isDown(mouseKey)) {
            Mouse.buttonsDown.push(mouseKey)
        }
    }

    /**
     * Remove a down mouse key
     */
    public static removeDown = (mouseKey: number) => {
        if (Mouse.isDown(mouseKey)) {
            Mouse.buttonsDown.splice(Mouse.buttonsDown.indexOf(mouseKey), 1)
        }
    }
}