import Renderer from './Renderer'
import Vector2 from '../math/Vector2'
import GameObject from '../game-object/GameObject'
import WebGL from '../core/WebGL'
import GameObjectModel from '../game-object/GameObjectModel'
import Matrices from '../math/Matrices'
import Vector4 from '../math/Vector4'
import GameObjectLight from '../game-object/GameObjectLight'
import Vector3 from '../math/Vector3'
import Matrix4 from '../math/Matrix4'
import MasterRenderer from './MasterRenderer'

/**
 * The entity renderer.
 * 
 * @author Stan Hurks
 */
export default class EntityRenderer extends Renderer {

    /**
     * The uniform location for the projection matrix
     */
    private locationProjectionMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the view matrix
     */
    private locationViewMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the transformation matrix
     */
    private locationTransformationMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the inverted view matrix
     */
    private locationInvertedViewMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the light position
     */
    private locationLightPosition: Array<WebGLUniformLocation> = []

    /**
     * The uniform location for the light color
     */
    private locationLightColor: Array<WebGLUniformLocation> = []

    /**
     * The uniform location for the light attenuation
     */
    private locationAttenuation: Array<WebGLUniformLocation> = []

    /**
     * The uniform location for the shine damper
     */
    private locationShineDamper!: WebGLUniformLocation

    /**
     * The uniform location for the reflectivity
     */
    private locationReflectivity!: WebGLUniformLocation

    /**
     * The uniform location for fake lighting
     */
    private locationUseFakeLighting!: WebGLUniformLocation

    /**
     * The uniform location for the sky color
     */
    private locationSkyColor!: WebGLUniformLocation

    /**
     * The uniform location for the number of rows
     */
    private locationSpriteRows!: WebGLUniformLocation

    /**
     * The uniform location for the sprite offset
     */
    private locationSpriteOffset!: WebGLUniformLocation

    /**
     * The uniform location for the day light value
     */
    private locationDayLightValue!: WebGLUniformLocation

    /**
     * The uniform location for the fog amount
     */
    private locationFogAmount!: WebGLUniformLocation

    /**
     * The uniform location for the plane
     */
    private locationPlane!: WebGLUniformLocation

    /**
     * Render the entities.
     */
    public renderEntities = (gameObjects: GameObject[]) => {
        const gl = WebGL.context

        const gameObjectsPerModel: {
            [modelName: string]: {
                model: GameObjectModel

                gameObjects: GameObject[]
            }
        } = {}

        for (const gameObject of gameObjects) {
            let entry = gameObjectsPerModel[gameObject.model.name]
            if (entry === undefined) {
                gameObjectsPerModel[gameObject.model.name] = {
                    model: gameObject.model,
                    gameObjects: [gameObject]
                }
            } else {
                entry.gameObjects.push(gameObject)
            }
        }

        for (const modelName of Object.keys(gameObjectsPerModel)) {
            const entry = gameObjectsPerModel[modelName]

            gl.activeTexture(gl.TEXTURE0)
            gl.bindTexture(gl.TEXTURE_2D, entry.model.texture.texture)
            gl.uniform1i(gl.getUniformLocation(this.program, 'textureSampler'), 0)

            if (entry.model.texture.isTransparent) {
                MasterRenderer.disableCulling()
            }
    
            this.loadFakeLighting(entry.model.texture.useFakeLighting || false)
            this.loadSpriteRows(entry.model.texture.sprite ? entry.model.texture.sprite.rows : 1)
            this.loadShineVariables(entry.model.texture.shineDamper || 1, entry.model.texture.reflectivity || 0)

            for (const gameObject of entry.gameObjects) {
                this.loadTransformationMatrix(Matrices.createTransformationMatrix(gameObject.transform))
                this.loadTilingOffset(new Vector2(gameObject.getTextureXOffset(), gameObject.getTextureYOffset()))

                for (const component of entry.model.components) {
                    if (component.name !== undefined && gameObject.hiddenComponentNames.indexOf(component.name) !== -1) {
                        continue
                    }

                    if (component.arrayBuffer) {
                        gl.bindBuffer(gl.ARRAY_BUFFER, component.arrayBuffer)
                    }
                    if (component.elementArrayBuffer) {
                        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, component.elementArrayBuffer)
                    }
    
                    const positionAttribLocation : number = gl.getAttribLocation(this.program, 'position');
                    gl.vertexAttribPointer(positionAttribLocation, 3, gl.FLOAT, false, 8 * Float32Array.BYTES_PER_ELEMENT, 0);
                    gl.enableVertexAttribArray(positionAttribLocation);
    
                    const textureCoordinatesAttribLocation : number = gl.getAttribLocation(this.program, 'textureCoordinates');
                    gl.vertexAttribPointer(textureCoordinatesAttribLocation, 2, gl.FLOAT, false, 8 * Float32Array.BYTES_PER_ELEMENT, 3 * Float32Array.BYTES_PER_ELEMENT);
                    gl.enableVertexAttribArray(textureCoordinatesAttribLocation);
    
                    const normalAttribLocation : number = gl.getAttribLocation(this.program, 'normal');
                    gl.vertexAttribPointer(normalAttribLocation, 3, gl.FLOAT, false, 8 * Float32Array.BYTES_PER_ELEMENT, 5 * Float32Array.BYTES_PER_ELEMENT);
                    gl.enableVertexAttribArray(normalAttribLocation);
    
                    gl.drawElements(gl.TRIANGLES, component.indicesCount, gl.UNSIGNED_SHORT, 0);
                    
                    gl.disableVertexAttribArray(positionAttribLocation);        
                    gl.disableVertexAttribArray(textureCoordinatesAttribLocation);
                    gl.disableVertexAttribArray(normalAttribLocation);
    
                    if (component.arrayBuffer) {
                        gl.bindBuffer(gl.ARRAY_BUFFER, null)
                    }
                    if (component.elementArrayBuffer) {
                        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null)
                    }
                }
            }

            if (entry.model.texture.isTransparent) {
                MasterRenderer.enableCulling()
            }

            gl.bindTexture(gl.TEXTURE_2D, null)
        }
    }

    /**
     * Load a clipping plane
     */
    public loadClipPlane = (clipPlane: Vector4) => {
        this.loadVector4(this.locationPlane, clipPlane)
    }

    /**
     * Load the amount of fog
     */
    public loadFogAmount = (fogAmount: number) => {
        this.loadFloat(this.locationFogAmount, fogAmount)
    }

    /**
     * Loads the day light value to glsl
     */
    public loadDayLightValue = (dayLightValue : number) => {
        this.loadFloat(this.locationDayLightValue, dayLightValue)
    }

    /**
     * Load the number of rows of the texture sprite
     */
    public loadSpriteRows = (spriteRows: number) => {
        this.loadFloat(this.locationSpriteRows, spriteRows)
    }

    /**
     * Load the tiling offset for texture atlas
     */
    public loadTilingOffset = (spriteOffset: Vector2) => {
        this.loadVector2(this.locationSpriteOffset, spriteOffset)
    }

    /**
     * Load a light to glsl.
     */
    public loadLights(lights: GameObjectLight[]) : void {
        for (let i: number = 0; i < WebGL.maxLights; i ++) {
            if (i < lights.length) {
                this.loadVector3(this.locationLightPosition[i], lights[i].position);
                this.loadVector3(this.locationLightColor[i], lights[i].color);
                this.loadVector3(this.locationAttenuation[i], lights[i].attenuation || new Vector3(1, 0, 0))
            }
            else {
                this.loadVector3(this.locationLightPosition[i], new Vector3(0, 0, 0))
                this.loadVector3(this.locationLightColor[i], new Vector3(0, 0, 0))
                this.loadVector3(this.locationAttenuation[i], new Vector3(1, 0, 0))
            }
        }
    }

    /**
     * Loads up the sky color
     */
    public loadSkyColor = (color: Vector3) => {
        this.loadVector3(this.locationSkyColor, color)
    }

    /**
     * Load fake lighting to glsl
     */
    public loadFakeLighting = (fakeLighting : boolean) => {
        this.loadBoolean(this.locationUseFakeLighting, fakeLighting)
    }

    /**
     * Loads up the shine variables
     */
    public loadShineVariables = (shineDamper: number, reflectivity: number) => {
        this.loadFloat(this.locationShineDamper, shineDamper)
        this.loadFloat(this.locationReflectivity, reflectivity)
    }

    /**
     * Load the transformation matrix to glsl.
     */
    public loadTransformationMatrix = (matrix: Matrix4) => {
        this.loadMatrix(this.locationTransformationMatrix, matrix)
    }

    /**
     * Load the projection matrix to glsl.
     */
    public loadProjectionMatrix = (matrix: Matrix4) => {
        this.loadMatrix(this.locationProjectionMatrix, matrix)
    }

    /**
     * Load the view matrix to glsl.
     */
    public loadViewMatrix = (matrix: Matrix4) => {
        this.loadMatrix(this.locationViewMatrix, matrix)

        const invertedViewMatrix = Matrix4.clone(matrix)
        invertedViewMatrix.invert()
        this.loadMatrix(this.locationInvertedViewMatrix, invertedViewMatrix)
    }

    protected getAllUniformLocations = () => {
        this.locationTransformationMatrix = this.getUniformLocation('transformationMatrix')
        this.locationProjectionMatrix = this.getUniformLocation('projectionMatrix')
        this.locationViewMatrix = this.getUniformLocation('viewMatrix')
        this.locationInvertedViewMatrix = this.getUniformLocation('invertedViewMatrix')
        this.locationShineDamper = this.getUniformLocation('shineDamper')
        this.locationReflectivity = this.getUniformLocation('reflectivity')
        this.locationUseFakeLighting = this.getUniformLocation('useFakeLighting')
        this.locationSkyColor = this.getUniformLocation('skyColor')
        this.locationSpriteOffset = this.getUniformLocation('spriteOffset')
        this.locationSpriteRows = this.getUniformLocation('spriteRows')
        this.locationDayLightValue = this.getUniformLocation('dayLightValue')
        this.locationFogAmount = this.getUniformLocation('fogAmount')
        this.locationPlane = this.getUniformLocation('plane')

        for (var i : number = 0; i < WebGL.maxLights; i ++) {
            this.locationLightPosition[i] = this.getUniformLocation(`lightPosition[${i}]`)
            this.locationLightColor[i] = this.getUniformLocation(`lightColor[${i}]`)
            this.locationAttenuation[i] = this.getUniformLocation(`attenuation[${i}]`)
        }
    }
}