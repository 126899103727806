import Renderer from '../Renderer'
import Terrain from './Terrain'
import WebGL from '../../core/WebGL'
import Matrices from '../../math/Matrices'
import Vector3 from '../../math/Vector3'
import Vector4 from '../../math/Vector4'
import GameObjectLight from '../../game-object/GameObjectLight'
import Matrix4 from '../../math/Matrix4'

/**
 * The renderer for the terrain.
 * 
 * @author Stan Hurks
 */
export default class TerrainRenderer extends Renderer {

    /**
     * The uniform location for the transformation matrix
     */
    private locationTransformationMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the projection matrix
     */
    private locationProjectionMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the view matrix
     */
    private locationViewMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the inversed view matrix
     */
    private locationInverseViewMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the light position
     */
    private locationLightPosition!: WebGLUniformLocation[]

    /**
     * The uniform location for the light color
     */
    private locationLightColor!: WebGLUniformLocation[]

    /**
     * The uniform location for the attenuation
     */
    private locationAttenuation!: WebGLUniformLocation[]

    /**
     * The uniform location for the shine damper
     */
    private locationShineDamper!: WebGLUniformLocation

    /**
     * The uniform location for the reflectivity
     */
    private locationReflectivity!: WebGLUniformLocation

    /**
     * The uniform location for the sky color
     */
    private locationSkyColor!: WebGLUniformLocation

    /**
     * The uniform location for the background texture
     */
    private locationBackgroundTexture!: WebGLUniformLocation

    /**
     * The uniform location for the red texture
     */
    private locationRedTexture!: WebGLUniformLocation

    /**
     * The uniform location for the green texture
     */
    private locationGreenTexture!: WebGLUniformLocation

    /**
     * The uniform location for the blue texture
     */
    private locationBlueTexture!: WebGLUniformLocation

    /**
     * The uniform location for the blend map texture
     */
    private locationBlendMapTexture!: WebGLUniformLocation

    /**
     * The uniform location for the day light value
     */
    private locationDayLightValue!: WebGLUniformLocation

    /**
     * The uniform location for the fog amount
     */
    private locationFogAmount!: WebGLUniformLocation

    /**
     * The uniform location for the plane
     */
    private locationPlane!: WebGLUniformLocation

    /**
     * Render all terrains
     */
    public renderTerrains = (terrains: Terrain[]) => {
        const gl = WebGL.context

        for (const terrain of terrains) {
            if (terrain.component.elementArrayBuffer !== undefined) {
                gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, terrain.component.elementArrayBuffer)
            }
            if (terrain.component.arrayBuffer !== undefined) {
                gl.bindBuffer(gl.ARRAY_BUFFER, terrain.component.arrayBuffer)
            }

            const positionsAttribLocation = gl.getAttribLocation(this.program, 'position')
            gl.vertexAttribPointer(positionsAttribLocation, 3, gl.FLOAT, false, 8 * Float32Array.BYTES_PER_ELEMENT, 0 * Float32Array.BYTES_PER_ELEMENT)
            gl.enableVertexAttribArray(positionsAttribLocation)
            
            const textureCoordinatesAttribLocation = gl.getAttribLocation(this.program, 'textureCoordinates')
            gl.vertexAttribPointer(textureCoordinatesAttribLocation, 2, gl.FLOAT, false, 8 * Float32Array.BYTES_PER_ELEMENT, 3 * Float32Array.BYTES_PER_ELEMENT)
            gl.enableVertexAttribArray(textureCoordinatesAttribLocation)
            
            const normalAttribLocation = gl.getAttribLocation(this.program, 'normal')
            gl.vertexAttribPointer(normalAttribLocation, 3, gl.FLOAT, false, 8 * Float32Array.BYTES_PER_ELEMENT, 5 * Float32Array.BYTES_PER_ELEMENT)
            gl.enableVertexAttribArray(normalAttribLocation)
            
            gl.activeTexture(gl.TEXTURE0)
            gl.bindTexture(gl.TEXTURE_2D, terrain.textures.black)
            gl.activeTexture(gl.TEXTURE1)
            gl.bindTexture(gl.TEXTURE_2D, terrain.textures.red)
            gl.activeTexture(gl.TEXTURE2)
            gl.bindTexture(gl.TEXTURE_2D, terrain.textures.green)
            gl.activeTexture(gl.TEXTURE3)
            gl.bindTexture(gl.TEXTURE_2D, terrain.textures.blue)
            gl.activeTexture(gl.TEXTURE4)
            gl.bindTexture(gl.TEXTURE_2D, terrain.textures.blendMap)

            this.loadShineVariables(1, 0)
            this.loadTransformationMatrix(Matrices.createTransformationMatrix({
                position: new Vector3(terrain.x, 0, terrain.z),
                rotation: new Vector3(0, 0, 0),
                scale: new Vector3(1, 1, 1)
            }))

            if (terrain.component.elementArrayBuffer === undefined) {
                gl.drawArrays(gl.TRIANGLES, 0, terrain.component.indicesCount)
            }
            else {
                gl.drawElements(gl.TRIANGLES, terrain.component.indicesCount, gl.UNSIGNED_SHORT, 0)
            }

            if (terrain.component.arrayBuffer !== undefined) {
                gl.bindBuffer(gl.ARRAY_BUFFER, null)
            }
            if (terrain.component.elementArrayBuffer !== undefined) {
                gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null)
            }

            gl.disableVertexAttribArray(positionsAttribLocation)
            gl.disableVertexAttribArray(textureCoordinatesAttribLocation)
            gl.disableVertexAttribArray(normalAttribLocation)
        }
    }

    /**
     * Load a clipping plane
     */
    public loadClipPlane = (clipPlane: Vector4) => {
        this.loadVector4(this.locationPlane, clipPlane)
    }

    /**
     * Load the amount of fog
     */
    public loadFogAmount = (fogAmount: number) => {
        this.loadFloat(this.locationFogAmount, fogAmount)
    }

    /**
     * Load the day light value
     */
    public loadDayLightValue = (dayLightValue: number) => {
        this.loadFloat(this.locationDayLightValue, dayLightValue)
    }

    /**
     * Load up the lights to glsl.
     */
    public loadLights = (lights: GameObjectLight[]) => {
        for (let i: number = 0; i < WebGL.maxLights; i ++) {
            if (i < lights.length) {
                this.loadVector3(this.locationLightPosition[i], lights[i].position)
                this.loadVector3(this.locationLightColor[i], lights[i].color)
                this.loadVector3(this.locationAttenuation[i], lights[i].attenuation || new Vector3(1, 0, 0))
            }
            else {
                this.loadVector3(this.locationLightPosition[i], new Vector3(0, 0, 0))
                this.loadVector3(this.locationLightColor[i], new Vector3(0, 0, 0))
                this.loadVector3(this.locationAttenuation[i], new Vector3(1, 0, 0))
            }
        }
    }

    /**
     * Loads up the sky color
     */
    public loadSkyColor = (color: Vector3) => {
        this.loadVector3(this.locationSkyColor, color)
    }

    /**
     * Loads up the shine variables
     */
    public loadShineVariables = (shineDamper: number, reflectivity: number) => {
        this.loadFloat(this.locationShineDamper, shineDamper)
        this.loadFloat(this.locationReflectivity, reflectivity)
    }

    /**
     * Load the transformation matrix to glsl.
     */
    public loadTransformationMatrix = (matrix: Matrix4) => {
        this.loadMatrix(this.locationTransformationMatrix, matrix)
    }

    /**
     * Load the projection matrix to glsl.
     */
    public loadProjectionMatrix = (matrix: Matrix4) => {
        this.loadMatrix(this.locationProjectionMatrix, matrix)
    }

    /**
     * Load the view matrix to glsl.
     */
    public loadViewMatrix = (matrix: Matrix4) => {
        this.loadMatrix(this.locationViewMatrix, matrix)

        const inverse: Matrix4 = Matrix4.clone(matrix)
        inverse.invert()

        this.loadMatrix(this.locationInverseViewMatrix, matrix)
    }

    protected getAllUniformLocations = () => {
        this.locationBackgroundTexture = this.getUniformLocation('backgroundTexture')
        this.locationRedTexture = this.getUniformLocation('redTexture')
        this.locationGreenTexture = this.getUniformLocation('greenTexture')
        this.locationBlueTexture = this.getUniformLocation('blueTexture')
        this.locationBlendMapTexture = this.getUniformLocation('blendMapTexture')
        this.loadInteger(this.locationBackgroundTexture, 0)
        this.loadInteger(this.locationRedTexture, 1)
        this.loadInteger(this.locationGreenTexture, 2)
        this.loadInteger(this.locationBlueTexture, 3)
        this.loadInteger(this.locationBlendMapTexture, 4)

        this.locationTransformationMatrix = this.getUniformLocation('transformationMatrix')
        this.locationProjectionMatrix = this.getUniformLocation('projectionMatrix')
        this.locationViewMatrix = this.getUniformLocation('viewMatrix')
        this.locationInverseViewMatrix = this.getUniformLocation('inverseViewMatrix')
        this.locationShineDamper = this.getUniformLocation('shineDamper')
        this.locationReflectivity = this.getUniformLocation('reflectivity')
        this.locationSkyColor = this.getUniformLocation('skyColor')
        this.locationDayLightValue = this.getUniformLocation('dayLightValue')
        this.locationFogAmount = this.getUniformLocation('fogAmount')
        this.locationPlane = this.getUniformLocation('plane')

        this.locationLightColor = []
        this.locationLightPosition = []
        this.locationAttenuation = []
        for (let i: number = 0; i < WebGL.maxLights; i ++) {
            this.locationLightColor[i] = this.getUniformLocation('lightColor[' + i + ']')
            this.locationLightPosition[i] = this.getUniformLocation('lightPosition[' + i + ']')
            this.locationAttenuation[i] = this.getUniformLocation('attenuation[' + i + ']')
        }
    }
}