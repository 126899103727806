import Renderer from './Renderer'
import GameObjectModelComponent from '../game-object/GameObjectModelComponent'
import Loader from '../game-object/loader/Loader'
import Camera from '../game-object/Camera'
import Canvas from '../core/Canvas'
import Matrices from '../math/Matrices'
import WebGL from '../core/WebGL'
import Environment from '../core/Environment'
import Vector3 from '../math/Vector3'
import Matrix4 from '../math/Matrix4'
import Maths from '../core/Maths'

/**
 * The rendering class for skyboxes.
 * 
 * @author Hurks IT
 */
export default class SkyboxRenderer extends Renderer {

    /**
     * The size of the skybox
     */
    private static SIZE: number = 500

    /**
     * The vertices of a skybox
     */
    private static VERTICES: number[] = [
        -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,

        -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,

        SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,

        -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,

        -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,

        -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,
        -SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE,
        SkyboxRenderer.SIZE, -SkyboxRenderer.SIZE,  SkyboxRenderer.SIZE
    ]

    /**
     * The rotation speed of a skybox
     */
    private static readonly ROTATE_SPEED: number = 0.4

    /**
     * The texture file names for a skybox
     */
    private static TEXTURE_FILES: string[] = ['right', 'left', 'top', 'bottom', 'back', 'front'];

    /**
     * The uniform location for the projection matrix
     */
    private locationProjectionMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the view matrix
     */
    private locationViewMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the day skybox
     */
    private locationDaySkyBox!: WebGLUniformLocation

    /**
     * The uniform location for the dawn skybox
     */
    private locationDawnSkyBox!: WebGLUniformLocation

    /**
     * The uniform location for the night skybox
     */
    private locationNightSkyBox!: WebGLUniformLocation

    /**
     * The uniform location for the day value
     */
    private locationDayValue!: WebGLUniformLocation

    /**
     * The uniform location for the dawn value
     */
    private locationDawnValue!: WebGLUniformLocation

    /**
     * The uniform location for the night value
     */
    private locationNightValue!: WebGLUniformLocation

    /**
     * The uniform location for the fog color
     */
    private locationFogColor!: WebGLUniformLocation

    /**
     * The current rotation of the skybox
     */
    private currentRotation: number = 0

    /**
     * The component
     */
    private component: GameObjectModelComponent = Loader.loadPositionsToVao(SkyboxRenderer.VERTICES, 3)

    /**
     * The day texture
     */
    private dayTexture!: WebGLTexture

    /**
     * The dawn texture
     */
    private dawnTexture!: WebGLTexture

    /**
     * The night texture
     */
    private nightTexture!: WebGLTexture

    public constructor (skyboxNameDay: string, skyboxNameDawn: string, skyboxNameNight: string) {
        super()
        this.dayTexture = Loader.loadCubeMap(this.getTextureFilesFromSkyboxName(skyboxNameDay))
        this.dawnTexture = Loader.loadCubeMap(this.getTextureFilesFromSkyboxName(skyboxNameDawn))
        this.nightTexture = Loader.loadCubeMap(this.getTextureFilesFromSkyboxName(skyboxNameNight))
    }

    /**
     * Update the skybox
     */
    public update = (camera: Camera) => {
        this.currentRotation += SkyboxRenderer.ROTATE_SPEED * Canvas.deltaTimeMS / 1000

        this.enable()
        this.loadViewMatrix(Matrices.createViewMatrix(camera))
        this.disable()
    }

    /**
     * Render the skybox
     */
    public render = (camera: Camera) => {
        const gl = WebGL.context
        
        this.loadProjectionMatrix(Matrices.createProjectionMatrix())
        this.loadViewMatrix(Matrices.createViewMatrix(camera))
        this.loadTimeValues(Environment.getDayFactor(), Environment.getDawnFactor(), Environment.getNightFactor())
        this.loadFogColor(Environment.getCurrentSkyColor())
        
        gl.bindBuffer(gl.ARRAY_BUFFER, this.component.arrayBuffer as WebGLBuffer)
        
        const positionsAttribLocation = gl.getAttribLocation(this.program, 'position')
        gl.vertexAttribPointer(positionsAttribLocation, 3, gl.FLOAT, false, 3 * Float32Array.BYTES_PER_ELEMENT, 0)
        gl.enableVertexAttribArray(positionsAttribLocation)
        
        gl.activeTexture(gl.TEXTURE0)
        gl.bindTexture(gl.TEXTURE_CUBE_MAP, this.dayTexture)
        gl.activeTexture(gl.TEXTURE1)
        gl.bindTexture(gl.TEXTURE_CUBE_MAP, this.dawnTexture)
        gl.activeTexture(gl.TEXTURE2)
        gl.bindTexture(gl.TEXTURE_CUBE_MAP, this.nightTexture)
        
        gl.drawArrays(gl.TRIANGLES, 0, this.component.indicesCount)

        gl.disableVertexAttribArray(positionsAttribLocation)
        gl.bindBuffer(gl.ARRAY_BUFFER, null)
    }

    /**
     * Load the fog color to glsl
     */
    public loadFogColor = (fogColor: Vector3) => {
        this.loadVector3(this.locationFogColor, fogColor)
    }

    /**
     * Load the time values to glsl.
     */
    public loadTimeValues = (dayValue: number, dawnValue: number, nightValue: number) => {
        this.loadFloat(this.locationDayValue, dayValue)
        this.loadFloat(this.locationDawnValue, dawnValue)
        this.loadFloat(this.locationNightValue, nightValue)
    }

    /**
     * Load the projection matrix to glsl.
     */
    public loadProjectionMatrix = (matrix: Matrix4) => {
        this.loadMatrix(this.locationProjectionMatrix, matrix)
    }

    /**
     * Load the view matrix to glsl
     */
    public loadViewMatrix = (matrix: Matrix4) => {
        matrix.m30 = 0
        matrix.m31 = 0
        matrix.m32 = 0
        matrix.rotate(Maths.toRadians(this.currentRotation), new Vector3(0, 1, 0))
        this.loadMatrix(this.locationViewMatrix, matrix)
    }

    protected getAllUniformLocations = () => {
        this.locationProjectionMatrix = this.getUniformLocation('projectionMatrix')
        this.locationViewMatrix = this.getUniformLocation('viewMatrix')

        this.locationDaySkyBox = this.getUniformLocation('daySkyBox')
        this.loadInteger(this.locationDaySkyBox, 0)
        this.locationDawnSkyBox = this.getUniformLocation('dawnSkyBox')
        this.loadInteger(this.locationDawnSkyBox, 1)
        this.locationNightSkyBox = this.getUniformLocation('nightSkyBox')
        this.loadInteger(this.locationNightSkyBox, 2)
        
        this.locationDayValue = this.getUniformLocation('dayValue')
        this.locationDawnValue = this.getUniformLocation('dawnValue')
        this.locationNightValue = this.getUniformLocation('nightValue')
        
        this.locationFogColor = this.getUniformLocation('fogColor')
    }

    /**
     * Get the texture files for a skybox name
     */
    private getTextureFilesFromSkyboxName = (skyboxName: string) => {
        const textureFiles: string[] = []
        for (let i: number = 0; i < 6; i ++) {
            textureFiles[i] = `skybox/${skyboxName}/${SkyboxRenderer.TEXTURE_FILES[i]}`
        }
        return textureFiles
    }
}