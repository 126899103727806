/**
 * All WebGL related functionality.
 * 
 * @author Stan Hurks
 */
export default class WebGL {

    /**
     * The version
     */
    public static version: 1|2|'unsupported'|'experimental'

    /**
     * The types of support the WebGL context has in the current browser
     */
    public static support = {
        depthTexture: false,
        frameBuffer: false
    }

    /**
     * The WebGL context
     */
    public static context: WebGLRenderingContext

    /**
     * The canvas element
     */
    public static canvas: HTMLCanvasElement

    /**
     * The maximum amount of lights possible in the current version
     * of GLSL ES
     */
    public static maxLights: number

    /**
     * Get an extension by name
     */
    public static getExtension = (name: string): any|null => {
        const vendors: string[] = ['ANGLE', 'OES', 'EXT', 'WEBGL', 'O', 'MS', 'MOZ', 'WEBKIT']
        
        // Get the extension from WebGL
        const extension = WebGL.context.getExtension(name)
        if (extension !== null) {
            return extension
        }

        // Get the extension by vendor prefix
        for (const vendor of vendors) {
            const extension = WebGL.context.getExtension(`${vendor}_${name}`)
            if (extension !== null) {
                return extension
            }
        }

        return null
    }
}