import GameObjectModelComponent from '../../game-object/GameObjectModelComponent'
import Maths from '../../core/Maths'
import Vector2 from '../../math/Vector2'
import Vector3 from '../../math/Vector3'
import Assets from '../../../core/Assets'
import Loader from '../../game-object/loader/Loader'

/**
 * A terrain in the game.
 * 
 * @author Stan Hurks
 */
export default class Terrain {

    /**
     * The size of the terrain
     */
    public static readonly SIZE: number = 512

    /**
     * The max height of the terrain
     */
    public static readonly MAX_HEIGHT: number = 40

    /**
     * The x coordinate of the terrain
     */
    public x: number

    /**
     * The heights per coordinate of the terrain
     */
    public heights: number[][] = []

    /**
     * The z coordinate of the terrain
     */
    public z: number

    /**
     * The component belonging to this terrain
     */
    public component!: GameObjectModelComponent

    /**
     * The textures
     */
    public textures!: {
        /**
         * The background texture in the blendmap
         */
        black: WebGLTexture

        /**
         * The red texture in the blendmap
         */
        red: WebGLTexture

        /**
         * The green texture in the blendmap
         */
        green: WebGLTexture

        /**
         * The blue texture in the blendmap
         */
        blue: WebGLTexture

        /**
         * The blendmap texture
         */
        blendMap: WebGLTexture
    }

    constructor(gridX: number, gridZ: number) {
        this.x = gridX * Terrain.SIZE
        this.z = gridZ * Terrain.SIZE
    }

     /**
     * Get the height of a terrain at a given coordinate
     * @param worldX the x coordinate
     * @param worldZ the z coordinate
     * @return
     */
    public getHeightOfTerrainAtCoordinate = (worldX: number, worldZ: number): number => {
        const terrainX: number = worldX - this.x
        const terrainZ: number = worldZ - this.z
        const gridSquareSize: number = Terrain.SIZE / (this.heights.length - 1)
        const gridX: number = Math.floor(terrainX / gridSquareSize)
        const gridZ: number = Math.floor(terrainZ / gridSquareSize)

        if (this.heights[gridX] === undefined || this.heights[gridX][gridZ] === undefined) {
            return 0
        }
        const xCoord: number = (terrainX % gridSquareSize) / gridSquareSize
        const zCoord: number = (terrainZ % gridSquareSize) / gridSquareSize
        let height: number
        if (xCoord <= (1 - zCoord)) {
            if (!this.heights[gridX + 1] || !this.heights[gridX] || !this.heights[gridX + 1][gridZ] || !this.heights[gridX][gridZ + 1] || !this.heights[gridX][gridZ]) {
                height = 0
            } else {
                height = Maths.baryCentric(
                    new Vector3(0, this.heights[gridX][gridZ], 0),
                    new Vector3(1, this.heights[gridX + 1][gridZ], 0),
                    new Vector3(0, this.heights[gridX][gridZ + 1], 1),
                    new Vector2(xCoord, zCoord)
                )
            }
        } else {
            if (!this.heights[gridX + 1] || !this.heights[gridX] || !this.heights[gridX + 1][gridZ] || !this.heights[gridX + 1][gridZ + 1] || !this.heights[gridX][gridZ + 1]) {
                height = 0
            } else {
                height = Maths.baryCentric(
                    new Vector3(1, this.heights[gridX + 1][gridZ], 0),
                    new Vector3(1, this.heights[gridX + 1][gridZ + 1], 1),
                    new Vector3(0, this.heights[gridX][gridZ + 1], 1),
                    new Vector2(xCoord, zCoord)
                )
            }
        }
        return height
    }

    /**
     * Generates a terrain.
     */
    public generateTerrain = (heightMap: string): Promise<void> => {
        return new Promise((resolve) => {
            const heightmapLocation = `textures/heightmaps/${heightMap}.png`
            
            Assets.load(heightmapLocation).then((base64) => {
                const image = new Image()
                image.src = `data:image/png;base64,${base64}`

                image.addEventListener("load", () => {
                    const canvas = document.createElement('canvas')
                    canvas.width = image.width
                    canvas.height = image.height

                    const context = canvas.getContext('2d')
                    if (context === null) {
                        throw new Error('No 2d context.')
                    }
                    context.drawImage(image, 0, 0, image.width, image.height)
    
                    const vertices: number[] = []
                    const normals: number[] = []
                    const textureCoordinates: number[] = []
                    const indices: number[] = []
                    let vertexPointer: number = 0
    
                    this.heights = []
                    for (var i : number = 0; i < image.width; i ++) {
                        this.heights.push([])
                    }
    
                    for (let rowIndex: number = 0; rowIndex < image.height; rowIndex ++) {
                        for (let columnIndex: number = 0; columnIndex < image.width; columnIndex ++) {
                            this.heights[columnIndex][rowIndex] = this.getHeight(columnIndex, rowIndex, context, image.width);
                                
                            vertices[vertexPointer * 3] = columnIndex / (image.width - 1) * Terrain.SIZE;
                            vertices[vertexPointer * 3 + 1] = this.getHeight(columnIndex, rowIndex, context, image.width);
                            vertices[vertexPointer * 3 + 2] = rowIndex / (image.height - 1) * Terrain.SIZE;
    
                            const heightLeft: number = this.getHeight(columnIndex-1, rowIndex, context, image.width)
                            const heightRight: number = this.getHeight(columnIndex+1, rowIndex, context, image.width)
                            const heightDown: number = this.getHeight(columnIndex, rowIndex-1, context, image.width)
                            const heightUp: number = this.getHeight(columnIndex, rowIndex+1, context, image.width)
                            const normal: Vector3 = new Vector3(heightLeft - heightRight, 2, heightDown - heightUp)
                            normal.normalise()
    
                            normals[vertexPointer * 3] = normal.x
                            normals[vertexPointer * 3 + 1] = normal.y
                            normals[vertexPointer * 3 + 2] = normal.z
                        
                            textureCoordinates[vertexPointer * 2] = columnIndex / (image.width - 1)
                            textureCoordinates[vertexPointer * 2 + 1] = rowIndex / (image.height - 1)
    
                            vertexPointer ++
                        }
                    }
                    
                    vertexPointer = 0
                    for (var rowIndex : number = 0; rowIndex < image.height - 1; rowIndex ++) {
                        for (var columnIndex : number = 0; columnIndex < image.width - 1; columnIndex ++) {
                            var topLeft : number = (rowIndex * image.height) + columnIndex
                            var topRight : number = topLeft + 1
                            var bottomLeft : number = ((rowIndex + 1) * image.height) + columnIndex
                            var bottomRight : number = bottomLeft + 1
    
                            //TRI 1
                            indices[vertexPointer++] = topLeft
                            indices[vertexPointer++] = bottomLeft
                            indices[vertexPointer++] = topRight
    
                            //TRI 2
                            indices[vertexPointer++] = topRight
                            indices[vertexPointer++] = bottomLeft
                            indices[vertexPointer++] = bottomRight
                        }
                    }

                    this.component = Loader.loadToVao(vertices, indices, textureCoordinates, normals)
                    resolve()
                })
            })
        })
    }

    /**
     * Generates a low poly terrain.
     */
    public generateLowPolyTerrain = (heightMap: string): Promise<void> => {
        return new Promise((resolve) => {
            const heightmapLocation = `${heightMap}.png`

            Assets.load(heightmapLocation).then((base64) => {
                const image = new Image()
                image.src = `data:image/png;base64,${base64}`

                image.addEventListener('load', () => {
                    const canvas = document.createElement('canvas')
                    canvas.width = image.width
                    canvas.height = image.height

                    const context = canvas.getContext('2d')
                    if (context === null) {
                        throw new Error('No 2d context.')
                    }
                    context.drawImage(image, 0, 0, image.width, image.height)
    
                    const vertices: number[] = []
                    const normals: number[] = []
                    const textureCoordinates: number[] = []
                    let vertexPointer: number = 0
    
                    this.heights = []
                    for (let i: number = 0; i < image.width; i++) {
                        this.heights.push([])
                    }
    
                    for (let rowIndex: number = 0; rowIndex < image.height; rowIndex++) {
                        for (let columnIndex: number = 0; columnIndex < image.width; columnIndex++) {
                            this.heights[columnIndex][rowIndex] = this.getHeight(columnIndex, rowIndex, context, image.width)
                                
                            vertices[vertexPointer * 3] = columnIndex / (image.width - 1) * Terrain.SIZE
                            vertices[vertexPointer * 3 + 1] = this.getHeight(columnIndex, rowIndex, context, image.width)
                            vertices[vertexPointer * 3 + 2] = rowIndex / (image.height - 1) * Terrain.SIZE
    
                            const heightLeft : number = this.getHeight(columnIndex-1, rowIndex, context, image.width)
                            const heightRight : number = this.getHeight(columnIndex+1, rowIndex, context, image.width)
                            const heightDown : number = this.getHeight(columnIndex, rowIndex-1, context, image.width)
                            const heightUp : number = this.getHeight(columnIndex, rowIndex+1, context, image.width)
                            const normal : Vector3 = new Vector3(heightLeft - heightRight, 2, heightDown - heightUp)
                            normal.normalise()
    
                            normals[vertexPointer * 3] = normal.x
                            normals[vertexPointer * 3 + 1] = normal.y
                            normals[vertexPointer * 3 + 2] = normal.z;             
                        
                            textureCoordinates[vertexPointer * 2] = columnIndex / (image.width - 1)
                            textureCoordinates[vertexPointer * 2 + 1] = rowIndex / (image.height - 1)
    
                            vertexPointer ++
                        }
                    }
                    vertexPointer = 0
                    const splitVertices: number[] = []
                    const splitTextureCoordinates: number[] = []
                    const splitNormals: number[] = []
                    for (let rowIndex: number = 0; rowIndex < image.height - 1; rowIndex++) {
                        for (let columnIndex: number = 0; columnIndex < image.width - 1; columnIndex++) {
                            const topLeft: number = (rowIndex * image.height) + columnIndex
                            const topRight: number = topLeft + 1
                            const bottomLeft: number = ((rowIndex + 1) * image.height) + columnIndex
                            const bottomRight: number = bottomLeft + 1
    
                            const indices: number[] = [topLeft, bottomLeft, topRight, topRight, bottomLeft, bottomRight]
                            for (let indicesIndex in indices) {
                                const index = indices[indicesIndex]
                                const normalsIndex = Number(indicesIndex) < 3 ? topLeft : bottomRight
    
                                splitVertices.push(vertices[index * 3], vertices[index * 3 + 1], vertices[index * 3 + 2])
                                splitTextureCoordinates.push(textureCoordinates[index * 2], textureCoordinates[index * 2 + 1])
                                splitNormals.push(normals[normalsIndex * 3], normals[normalsIndex * 3 + 1], normals[normalsIndex * 3 + 2])
                            }
                        }
                    }
                    this.component = Loader.loadToVao(splitVertices, [], splitTextureCoordinates, splitNormals)
                    resolve()
                })
            })
        })
    }

    /**
     * Get the terrain height at a given pixel coordinate within a 2d context.
     */
    private getHeight = (x: number, z: number, context: CanvasRenderingContext2D, maxSize: number): number => {
        if (x < 0 || x >= maxSize || z < 0 || z >= maxSize) {
            return 0
        }
        
        const imageData = context.getImageData(x, z, 1, 1)
        const r: number = imageData.data[0]
        const g: number = imageData.data[1]
        const b: number = imageData.data[2]
        
        let height: number = (r + g + b) / 3

        height /= 255
        height *= 2
        height -= 1
        height *= Terrain.MAX_HEIGHT

        return height
    }
}