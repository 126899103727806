import React from 'react'
import WebGL from './WebGL'
import { Subscription } from 'rxjs'
import Events from '../../core/Events'
import GameLoop from '../game-loop/GameLoop'
import MainGameLoop from '../game-loop/MainGameLoop'
import Keyboard from '../input/Keyboard'
import Mouse from '../input/Mouse'

/**
 * The state
 */
interface CanvasState {

    /**
     * The game loop
     */
    gameLoop: GameLoop

    /**
     * The window dimensions
     */
    windowDimensions: {
        width: number

        height: number
    }
}

/**
 * The game canvas.
 * 
 * @author Stan Hurks
 */
export default class Canvas extends React.Component<any, CanvasState> {

    /**
     * The amount of ms passed since the previous frame
     */
    public static deltaTimeMS: number = 0

    /**
     * The last frame time in MS
     */
    public static lastFrameTimeMS: number = new Date().getTime()

    /**
     * The reference to the canvas element
     */
    private canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef()

    /**
     * The subscription to the window resize event
     */
    private subscriptionWindowResize!: Subscription

    constructor(props: any) {
        super(props)

        this.state = {
            gameLoop: new MainGameLoop(),

            windowDimensions: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        }
    }

    public componentDidMount = () => {

        // Initialize WebGL
        this.initializeWebGL()

        // Change the viewport when the window resizes
        this.subscriptionWindowResize = Events.window.resize.subscribe(() => {
            this.setState({
                windowDimensions: {
                    width: window.innerWidth,
                    height: window.innerHeight
                }
            }, () => {
                this.updateViewport()
            })
        })

        // Initialize the gameloop
        this.state.gameLoop.initialize().then((response) => {

            // Update the viewport
            this.updateViewport()

            // Loop the game loop
            const loop = () => {

                // Update the canvas
                this.update()

                // Perform the game loop
                this.state.gameLoop.loop()

                // Reset inputs
                Keyboard.keysReleased = []
                Mouse.resetReleased()
                Mouse.scrollDeltaX = 0
                Mouse.scrollDeltaY = 0
                Mouse.deltaX = 0
                Mouse.deltaY = 0
                Mouse.mobileDeltaX = 0
                Mouse.mobileDeltaY = 0

                // Request new animation frame
                requestAnimationFrame(loop)
            }

            // Request new animation frame
            requestAnimationFrame(loop)
        }).catch((response) => {
            console.error('Could not initialize game loop.', response)
        })
    }

    public componentWillUnmount = () => {
        this.subscriptionWindowResize.unsubscribe()
    }

    public render = () => {
        return (
            <canvas
                id="canvas"
                width={this.state.windowDimensions.width}
                height={this.state.windowDimensions.height}
                ref={this.canvasRef}
            />
        )
    }

    /**
     * Initializes the canvas
     */
    private initializeWebGL = () => {
        if (!this.canvasRef.current) {
            return
        }

        // Set the canvas element in WebGL
        WebGL.canvas = this.canvasRef.current

        // Initialize the context
        let gl = this.canvasRef.current.getContext('webgl2')
        if (gl !== null) {
            WebGL.version = 2
            WebGL.context = gl as WebGLRenderingContext
            WebGL.support = {
                ...WebGL.support,
                depthTexture: true,
                frameBuffer: true
            }
        } else {
            gl = this.canvasRef.current.getContext('webgl')
            
            // Determine the WebGL version
            if (gl === null) {
                gl = this.canvasRef.current.getContext('experimental-webgl')

                if (gl === null) {
                    WebGL.version = 'unsupported'

                    throw new Error('No webgl support by browser.')
                } else {
                    WebGL.version = 'experimental'
                }
            } else {
                WebGL.version = 1
            }

            // Determine WebGL support
            if (WebGL.getExtension('draw_buffers') !== null) {
                WebGL.support.frameBuffer = false
                console.info('No framebuffer support.')
            }
            if (WebGL.getExtension('depth_texture') !== null) {
                WebGL.support.depthTexture = false
                console.info('No depth texture support.')
            }
        }

        // Set GLSL ES variables
        const maxVaryingVectors = WebGL.context.getParameter(WebGL.context.MAX_VARYING_VECTORS)
        if (maxVaryingVectors <= 16) {
            WebGL.maxLights = maxVaryingVectors - 5
        } else {
            WebGL.maxLights = 8
        }
    }

    /**
     * Update the display
     */
    private update = () => {
        const currentTimeMS = new Date().getTime()
        
        // Update frame times
        Canvas.deltaTimeMS = currentTimeMS - Canvas.lastFrameTimeMS
        Canvas.lastFrameTimeMS = currentTimeMS
    }

    /**
     * Updates the viewport in WebGL
     */
    private updateViewport = () => {
        WebGL.context.viewport(0, 0, this.state.windowDimensions.width, this.state.windowDimensions.height)
    }
}