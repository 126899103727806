import WebGL from '../core/WebGL'
import Assets from '../../core/Assets'
import Vector2 from '../math/Vector2'
import Vector3 from '../math/Vector3'
import Vector4 from '../math/Vector4'
import Matrix4 from '../math/Matrix4'

/**
 * The base class for each renderer class.
 * 
 * @author Stan Hurks
 */
export default abstract class Renderer {

    /**
     * The program in WebGL
     */
    protected program!: WebGLProgram

    /**
     * The vertex shader
     */
    protected vertexShader!: WebGLShader

    /**
     * The fragment shader
     */
    protected fragmentShader!: WebGLShader

    /**
     * Enable this shader program
     */
    public enable = () => {
        WebGL.context.useProgram(this.program)
    }

    /**
     * Disable this shader program
     */
    public disable = () => {
        WebGL.context.useProgram(null)
    }

    /**
     * Clean up the renderer
     */
    public cleanUp = () => {
        this.disable()
        const gl = WebGL.context
        gl.deleteShader(this.fragmentShader);
        gl.deleteShader(this.fragmentShader);
        gl.deleteProgram(this.program);
    }

    /**
     * Initialize the renderer
     */
    public initialize = (shaderName: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            Assets.load(`shaders/${shaderName}/${shaderName}-fragment.glsl`).then((shader) => {
                const fragmentShaderContent = shader.split('MAX_LIGHTS').join(WebGL.maxLights.toString())

                Assets.load(`shaders/${shaderName}/${shaderName}-vertex.glsl`).then((shader) => {
                    const vertexShaderContent = shader.split('MAX_LIGHTS').join(WebGL.maxLights.toString())
    
                    // Initialize the shader files
                    this.initializeShaderFiles(shaderName, vertexShaderContent, fragmentShaderContent)
                    
                    // Initialize the shader program
                    this.initializeShaderProgram(shaderName)
                    
                    
                    // Get all the uniform locations
                    this.enable()
                    this.getAllUniformLocations()
                    this.disable()

                    resolve()
                }).catch((err) => {
                    throw new Error(`Could not load vertex shader: ${shaderName}.\n${err}`)
                })
            }).catch((err) => {
                throw new Error(`Could not load fragment shader: ${shaderName}.\n${err}`)
            })
        })
    }

    /**
     * Get all the uniform locations
     */
    protected abstract getAllUniformLocations: () => void

    /**
     * Get a uniform location
     */
    protected getUniformLocation (uniformName: string): WebGLUniformLocation {
        const uniformLocation = WebGL.context.getUniformLocation(this.program, uniformName);
        if (uniformLocation == null) {
            throw new Error(`Could not get uniform location: ${uniformName}`)
        }
        return uniformLocation
    }

    /**
     * Load up a float to glsl
     */
    protected loadFloat = (uniformLocation: WebGLUniformLocation, value: number) => {
        WebGL.context.uniform1f(uniformLocation, value)
    }

    /**
     * Load up an integer to glsl
     */
    protected loadInteger = (uniformLocation: WebGLUniformLocation, value: number) => {
        WebGL.context.uniform1i(uniformLocation, Number(value.toFixed(0)))
    }

    /**
     * Load up a vector2 to glsl
     */
    protected loadVector2 = (uniformLocation: WebGLUniformLocation, value: Vector2) => {
        WebGL.context.uniform2f(uniformLocation, value.x, value.y)
    }

    /**
     * Load up a vector3 to glsl
     */
    protected loadVector3 = (uniformLocation: WebGLUniformLocation, value: Vector3) => {
        WebGL.context.uniform3f(uniformLocation, value.x, value.y, value.z)
    }

    /**
     * Load up a vector4 to glsl
     */
    protected loadVector4 = (uniformLocation: WebGLUniformLocation, value: Vector4) => {
        WebGL.context.uniform4f(uniformLocation, value.x, value.y, value.z, value.w)
    }

    /**
     * Load up a matrix to glsl
     */
    protected loadMatrix (uniformLocation: WebGLUniformLocation, value: Matrix4) {
        WebGL.context.uniformMatrix4fv(uniformLocation, false, [
            value.m00,
            value.m01,
            value.m02,
            value.m03,
            value.m10,
            value.m11,
            value.m12,
            value.m13,
            value.m20,
            value.m21,
            value.m22,
            value.m23,
            value.m30,
            value.m31,
            value.m32,
            value.m33,                        
        ]);
    }

    /**
     * Load up a boolean to glsl
     */
    protected loadBoolean = (uniformLocation: WebGLUniformLocation, value: boolean) => {
        this.loadFloat(uniformLocation, value ? 1 : 0)
    }

    /**
     * Initialize the shader files.
     */
    private initializeShaderFiles = (shaderName: string, vertexContent: string, fragmentContent: string) => {
        const gl : WebGLRenderingContext = WebGL.context

        // Create the shaders in WebGL
        const vertexShader = gl.createShader(gl.VERTEX_SHADER)
        if (vertexShader === null) {
            throw new Error('Vertex shader could not be created.')
        }
        const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)
        if (fragmentShader === null) {
            throw new Error('Fragment shader could not be created.')
        }
        this.fragmentShader = fragmentShader
        this.vertexShader = vertexShader

        // Compile the shaders
        gl.shaderSource(this.vertexShader, vertexContent)
        gl.shaderSource(this.fragmentShader, fragmentContent)
        gl.compileShader(this.vertexShader)
        if (!gl.getShaderParameter(this.vertexShader, gl.COMPILE_STATUS)) {
            throw new Error(`Error compiling ${shaderName} vertex shader: ${gl.getShaderInfoLog(this.vertexShader)}`)
        }
        gl.compileShader(this.fragmentShader)
        if (!gl.getShaderParameter(this.fragmentShader, gl.COMPILE_STATUS)) {
            throw new Error(`Error compiling ${shaderName} fragment shader: ${gl.getShaderInfoLog(this.fragmentShader)}`)
        }
    }

    /**
     * Initialize the shader program
     */
    private initializeShaderProgram = (shaderName: string) => {
        const gl : WebGLRenderingContext = WebGL.context
        const program = gl.createProgram()
        if (program === null) {
            throw new Error('Could not create shader program.')
        }

        this.program = program
        
        // Attach the shaders to the program
        gl.attachShader(this.program, this.vertexShader)
        gl.attachShader(this.program, this.fragmentShader)

        // Link the program
        gl.linkProgram(this.program)
        if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
            console.error(gl.getProgramInfoLog(this.program))
            throw new Error(`Error linking ${shaderName} program: ${gl.getProgramInfoLog(this.program)}`)
        }

        // Validate the program
        gl.validateProgram(this.program);
        if (!gl.getProgramParameter(this.program, gl.VALIDATE_STATUS)) {
            throw new Error(`Error validating ${shaderName} program: ${gl.getProgramInfoLog(this.program)}`)
        }
    }
}