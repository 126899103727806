import Vector3 from '../math/Vector3'
import Player from './Player'
import Maths from '../core/Maths'
import Mouse from '../input/Mouse'
import Keyboard from '../input/Keyboard'
import Canvas from '../core/Canvas'
import { KeyCode } from '../../core/KeyCode'
import MouseButton from '../../core/MouseButton'

/**
 * The camera
 */
export default class Camera {

    /**
     * The speed at which the camera can zoom
     */
    public static readonly ZOOM_SPEED: number = 100

    /**
     * The minimum distance from the player
     */
    public static readonly MIN_DISTANCE_FROM_PLAYER: number = 15

    /**
     * The maximum distance from the player
     */
    public static readonly MAX_DISTANCE_FROM_PLAYER: number = 150

    /**
     * The speed at which the pitch can change
     */
    public static readonly PITCH_SPEED: number = 90

    /**
     * The minimum value for the pitch
     */
    public static readonly MIN_PITCH: number = 15

    /**
     * The maximum value for the pitch
     */
    public static readonly MAX_PITCH: number = 75

    /**
     * The speed at which the angle can change
     */
    public static readonly ANGLE_SPEED: number = 225

    /**
     * The distance from the player
     */
    public distanceFromPlayer: number = 50

    /**
     * The angle around the player
     */
    public angleAroundPlayer: number = 0

    /**
     * The position of the camera
     */
    public position: Vector3 = new Vector3(0, 0, 0)

    /**
     * The pitch of the camera
     */
    public pitch: number = 30

    /**
     * The yaw of the camera
     */
    public yaw: number = 0

    /**
     * The roll of the camera
     */
    public roll!: number

    /**
     * The player to follow
     */
    public player: Player

    constructor(player: Player) {
        this.player = player
    }

    /**
     * Invert the pitch of the camera
     */
    public invertPitch = () => {
        this.pitch = -this.pitch
    }

    /**
     * Move the camera
     */
    public move = () => {
        this.calculateZoom()
        this.calculatePitch()
        this.calculateAngleAroundPlayer()
        const horizontalDistanceFromPlayer: number = this.calculateHorizontalDistance()
        const verticalDistanceFromPlayer: number = this.calculateVerticalDistance()
        this.calculateCameraPosition(horizontalDistanceFromPlayer, verticalDistanceFromPlayer)
        this.yaw = 180 - (this.player.transform.rotation.y + this.angleAroundPlayer)
    }

    /**
     * Calculate the cameras position
     */
    private calculateCameraPosition = (horizontalDistanceFromPlayer: number, verticalDistanceFromPlayer: number) => {
        const theta: number = this.player.transform.rotation.y + this.angleAroundPlayer
        const offsetX: number = (horizontalDistanceFromPlayer * Math.sin(Maths.toRadians(theta)))
        const offsetZ: number = (horizontalDistanceFromPlayer * Math.cos(Maths.toRadians(theta)))

        this.position.x = this.player.transform.position.x - offsetX
        this.position.z = this.player.transform.position.z - offsetZ
        this.position.y = this.player.transform.position.y + verticalDistanceFromPlayer
    }

    /**
     * Calculate the horizontal distance to the player
     */
    private calculateHorizontalDistance = (): number => {
        return this.distanceFromPlayer * Math.cos(Maths.toRadians(this.pitch))
    }

    /**
     * Calculate the vertical distance to the player
     */
    private calculateVerticalDistance = (): number => {
        return this.distanceFromPlayer * Math.sin(Maths.toRadians(this.pitch))
    }

    /**
     * Calculate the zoom for the camera.
     */
    private calculateZoom = () => {
        const zoom: number = Mouse.scrollDeltaY === 0 || Keyboard.isKeyDown(KeyCode.R) || Keyboard.isKeyDown(KeyCode.E)
            ? Camera.ZOOM_SPEED * Canvas.deltaTimeMS / 1000 / 5000
            : Mouse.scrollDeltaY * Canvas.deltaTimeMS / 1000

        if (Keyboard.isKeyDown(KeyCode.R)) {
            this.distanceFromPlayer -= zoom
        }
        else if (Keyboard.isKeyDown(KeyCode.E)) {
            this.distanceFromPlayer += zoom
        }
        else {
            this.distanceFromPlayer += zoom
        }
        
        if (this.distanceFromPlayer > Camera.MAX_DISTANCE_FROM_PLAYER) {
            this.distanceFromPlayer = Camera.MAX_DISTANCE_FROM_PLAYER
        }
        else if (this.distanceFromPlayer < Camera.MIN_DISTANCE_FROM_PLAYER) {
            this.distanceFromPlayer = Camera.MIN_DISTANCE_FROM_PLAYER
        }
    }

    /**
     * Calculates the pitch
     */
    private calculatePitch = () => {
        if (Mouse.isDown(MouseButton.LEFT) 
            || (
                (
                    Mouse.mobileDeltaY >= Mouse.minSwipeDistance
                    || Mouse.mobileDeltaY <= -Mouse.minSwipeDistance
                ) 
                &&
                Math.abs(Mouse.mobileDeltaY) > Math.abs(Mouse.mobileDeltaX)
            )
        ) {
            let newPitch = Mouse.isDown(MouseButton.LEFT) 
                ? this.pitch + Mouse.deltaY * Canvas.deltaTimeMS / 1000 * 20
               : this.pitch + Mouse.mobileDeltaY * Canvas.deltaTimeMS / 1000

            if (newPitch > Camera.MAX_PITCH) {
                newPitch = Camera.MAX_PITCH
            }
            else if (newPitch < Camera.MIN_PITCH) {
                newPitch = Camera.MIN_PITCH
            }
            this.pitch = newPitch
        } else {
            const pitchChange: number = Camera.PITCH_SPEED * Canvas.deltaTimeMS / 1000
            if (Keyboard.isKeyDown(KeyCode.DOWN)) {
                if (this.pitch + pitchChange > Camera.MAX_PITCH) {
                    this.pitch = Camera.MAX_PITCH
                    return
                }
                this.pitch += pitchChange
            }
            else if (Keyboard.isKeyDown(KeyCode.UP)) {
                if (this.pitch - pitchChange < Camera.MIN_PITCH) {
                    this.pitch = Camera.MIN_PITCH
                    return
                }
                this.pitch -= pitchChange
            }
        }
    }

    /**
     * Calculate the angle around the player.
     */
    private calculateAngleAroundPlayer = () => {
        if (Mouse.isDown(MouseButton.LEFT)
            || (
                (
                    Mouse.mobileDeltaX >= Mouse.minSwipeDistance
                    || Mouse.mobileDeltaX <= -Mouse.minSwipeDistance
                )
                && Math.abs(Mouse.mobileDeltaX) > Math.abs(Mouse.mobileDeltaY)
            )
        ) {
            this.angleAroundPlayer += Mouse.isDown(MouseButton.LEFT) 
                ? Mouse.deltaX * Canvas.deltaTimeMS / 1000 * 20
                : Mouse.mobileDeltaX * Canvas.deltaTimeMS / 1000
        }
        else if (Keyboard.isKeyDown(KeyCode.LEFT)) {
            this.angleAroundPlayer -= Camera.ANGLE_SPEED * Canvas.deltaTimeMS / 1000
        }
        else if (Keyboard.isKeyDown(KeyCode.RIGHT)) {
            this.angleAroundPlayer += Camera.ANGLE_SPEED * Canvas.deltaTimeMS / 1000
        }
    }
}