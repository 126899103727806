import React from 'react'
import './radial-progress.scss'
import { Subscription } from 'rxjs'
import Events from '../../core/Events'

/**
 * The props
 */
interface RadialProgressProps {
    
    /**
     * The percentage of the stroke around the circle
     */
    percentage: number

    /**
     * The size of the spinner
     * default: 80
     */
    size?: number

    /**
     * The size of the outer stroke
     * default: 8
     */
    strokeSize?: number

    /**
     * The size of the inner stroke
     * default: 4
     */
    innerStrokeSize?: number
}

/**
 * The state
 */
interface RadialProgressState {
    /**
     * The size of the spinner
     * default: 80
     */
    size: number

    /**
     * The size of the outer stroke
     * default: 8
     */
    strokeSize: number

    /**
     * The size of the inner stroke
     * default: 4
     */
    innerStrokeSize: number
}

/**
 * The radial progress for when the API is loading data in a component.
 * 
 * @author Stan Hurks
 */
export default class RadialProgress extends React.Component<RadialProgressProps, RadialProgressState> {

    /**
     * The subscription for the window resize event
     */
    private subscriptionWindowResize!: Subscription

    constructor(props: any) {
        super(props)

        this.state = {
            size: this.getScale() * (this.props.size || 80),

            strokeSize: this.getScale() * (this.props.strokeSize || 8),

            innerStrokeSize: this.getScale() * (this.props.innerStrokeSize || 4)
        }
    }

    public componentDidMount = () => {
        this.subscriptionWindowResize = Events.window.resize.subscribe(this.onWindowResize)
    }

    public componentWillUnmount = () => {
        this.subscriptionWindowResize.unsubscribe()
    }

    public render = () => {
        return (
            <div className="radial-progress" style={{
                maxHeight: this.state.size,
                maxWidth: this.state.size
            }}>
                <svg className="radial-progress-ring" height={this.state.size} width={this.state.size}>
                    <circle className="radial-progress-ring-circle-inner"
                        stroke="#777777"
                        strokeWidth={this.state.innerStrokeSize}
                        strokeDasharray={`${this.calculateCircumference(this.state.innerStrokeSize)} ${this.calculateCircumference(this.state.innerStrokeSize)}`}
                        strokeDashoffset={0}
                        fill="transparent"
                        r={this.calculateRadius(this.state.innerStrokeSize) - ((this.state.strokeSize) - (this.state.innerStrokeSize)) / 2}
                        cx={(this.state.size) / 2}
                        cy={(this.state.size) / 2} />

                    <circle className="radial-progress-ring-circle-outer"
                        stroke="#ff1744"
                        strokeWidth={this.state.strokeSize}
                        strokeDasharray={`${this.calculateCircumference(this.state.strokeSize)} ${this.calculateCircumference(this.state.strokeSize)}`}
                        strokeDashoffset={this.calculateCircumference((this.state.strokeSize)) - (this.props.percentage / 100) * this.calculateCircumference((this.state.strokeSize))}
                        fill="transparent"
                        r={this.calculateRadius(this.state.strokeSize)}
                        cx={(this.state.size) / 2}
                        cy={(this.state.size) / 2} />
                </svg>

                {
                    this.props.children
                    ?
                    <div className="radial-progress-label">
                        {
                            this.props.children
                        }
                    </div>
                    :
                    <div className="radial-progress-label">
                        {
                            this.props.percentage.toFixed(0)
                        }%
                    </div>
                }
            </div>
        )
    }

    /**
     * Whenever the window resizes
     */
    private onWindowResize = () => {
        this.setState({
            size: this.getScale() * 80,

            strokeSize: this.getScale() * 8,

            innerStrokeSize: this.getScale() * 4
        })
    }

    /**
     * Calculate the circumference of the stroke
     * @param strokeSize the size of the stroke
     */
    private calculateCircumference = (strokeSize: number): number => {
        return this.calculateRadius(strokeSize) * 2 * Math.PI
    }

    /**
     * Calculate the radius of the stroke
     * @param strokeSize the size of the stroke
     */
    private calculateRadius = (strokeSize: number): number => {
        return ((this.state.size) / 2) - (strokeSize / 2)
    }

    /**
     * Get the scale
     */
    private getScale = () => {
        return window.innerWidth < 1000 ? 6 / 9 : 1
    }
}