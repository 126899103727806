/**
 * All keyboard functionality.
 * 
 * @author Stan Hurks
 */
export default class Keyboard {

    /**
     * All keys that are down
     */
    public static keysDown: number[] = []

    /**
     * All keys that are released
     */
    public static keysReleased: number[] = []

    /**
     * Checks if a key is pressed
     */
    public static isKeyDown (keyCode: number): boolean {
        return this.keysDown.indexOf(keyCode) !== -1
    }

    /**
     * Adds a key down
     */
    public static addKeyDown (keyCode: number) {
        if (!this.isKeyDown(keyCode)) {
            this.keysDown.push(keyCode)
        }
    }

    /**
     * Removes a key down
     */
    public static removeKeyDown (keyCode: number) {
        if (this.isKeyDown(keyCode)) {
            this.keysDown.splice(this.keysDown.indexOf(keyCode), 1)
        }
    }

    /**
     * Checks if a key is released
     */
    public static isKeyReleased (keyCode: number): boolean {
        return this.keysReleased.indexOf(keyCode) !== -1
    }

    /**
     * Adds a key released
     */
    public static addKeyReleased (keyCode: number) {
        if (!this.isKeyReleased(keyCode)) {
            this.keysReleased.push(keyCode)
        }
    }

    /**
     * Removes a key released
     */
    public static removeKeyReleased (keyCode: number) {
        if (this.isKeyReleased(keyCode)) {
            this.keysReleased.splice(this.keysReleased.indexOf(keyCode), 1);
        }
    }
}