/**
 * The mouse buttons.
 * 
 * @author Stan Hurks
 */
export default class MouseButton {

    public static readonly LEFT = 0

    public static readonly MIDDLE = 1

    public static readonly RIGHT = 2
}