import GameObject from './GameObject'
import Canvas from '../core/Canvas'
import Maths from '../core/Maths'
import Keyboard from '../input/Keyboard'
import { KeyCode } from '../../core/KeyCode'
import Terrain from '../renderer/terrain/Terrain'

/**
 * The player in the game
 */
export default class Player extends GameObject {

    /**
     * The factor at which the walk speed multiplies while running
     */
    public static readonly RUN_MULTIPLIER: number = 2

    /**
     * The speed at which the player walks
     */
    public static readonly WALK_SPEED: number = 15

    /**
     * The speed at which the player can turn
     */
    public static readonly TURN_SPEED: number = 160

    /**
     * The gravity at which the player falls
     */
    public static readonly GRAVITY: number = -50

    /**
     * The intensity at which the player can jump
     */
    public static readonly JUMP_POWER: number = 30

    /**
     * The current speed of the player
     */
    public currentSpeed: number = 0

    /**
     * The current speed at which the player turns
     */
    public currentTurnSpeed: number = 0

    /**
     * The current speed at which the player is jumping
     */
    public upwardsSpeed: number = 0

    /**
     * Whether or not the player is in the air
     */
    public isInAir: boolean = false

    /**
     * Move the player
     */
    public move (terrain: Terrain|null) : void {
        this.checkInputs()
        this.increaseRotation(0, this.currentTurnSpeed * Canvas.deltaTimeMS / 1000, 0)
        const distance : number = this.currentSpeed * Canvas.deltaTimeMS / 1000

        const dx : number = distance * Math.sin(Maths.toRadians(this.transform.rotation.y))
        const dz : number = distance * Math.cos(Maths.toRadians(this.transform.rotation.y))

        this.increasePosition(dx, 0,  dz)
        this.upwardsSpeed += Player.GRAVITY * Canvas.deltaTimeMS / 1000
        this.increasePosition(0, this.upwardsSpeed * Canvas.deltaTimeMS / 1000, 0)

        const terrainHeight : number = terrain === null
            ? 0
            : terrain.getHeightOfTerrainAtCoordinate(this.transform.position.x, this.transform.position.z);

        if (this.transform.position.y < terrainHeight) {
            this.upwardsSpeed = 0
            this.isInAir = false
            this.transform.position.y = terrainHeight
        }
    }

    /**
     * Jump the player
     */
    private jump = () => {
        if (this.isInAir) {
            return
        }

        this.upwardsSpeed = Player.JUMP_POWER
        this.isInAir = true
    }

    /**
     * Check for inputs to move the player.
     */
    private checkInputs = () => {
        const multiplier: number = Keyboard.isKeyDown(KeyCode.T) ? Player.RUN_MULTIPLIER : 1

        if (Keyboard.isKeyDown(KeyCode.W)) {
            this.currentSpeed = Player.WALK_SPEED * multiplier
        }
        else if (Keyboard.isKeyDown(KeyCode.S)) {
            this.currentSpeed = -Player.WALK_SPEED * multiplier
        }
        else {
            this.currentSpeed = 0
        }

        if (Keyboard.isKeyDown(KeyCode.D)) {
            this.currentTurnSpeed = -Player.TURN_SPEED
        }
        else if (Keyboard.isKeyDown(KeyCode.A)) {
            this.currentTurnSpeed = Player.TURN_SPEED
        }
        else {
            this.currentTurnSpeed = 0
        }

        if (Keyboard.isKeyDown(KeyCode.SPACE)) {
            this.jump()
        }
    }
}