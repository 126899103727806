import GameLoop from './GameLoop'
import MasterRenderer from '../renderer/MasterRenderer'
import GameObjectRenderer from '../renderer/GameObjectRenderer'
import TerrainRenderer from '../renderer/terrain/TerrainRenderer'
import SkyboxRenderer from '../renderer/SkyboxRenderer'
import WaterRenderer from '../renderer/water/WaterRenderer'
import Camera from '../game-object/Camera'
import Player from '../game-object/Player'
import Vector3 from '../math/Vector3'
import WaterFrameBuffers from '../renderer/water/WaterFrameBuffers'
import OBJLoader from '../game-object/loader/OBJLoader'
import Loader from '../game-object/loader/Loader'
import Vector4 from '../math/Vector4'
import Terrain from '../renderer/terrain/Terrain'
import Environment from '../core/Environment'

/**
 * The main game loop.
 * 
 * @author Stan Hurks
 */
export default class MainGameLoop implements GameLoop {
    
    /**
     * The master renderer
     */
    private masterRenderer!: MasterRenderer

    /**
     * The game object renderer
     */
    private gameObjectRenderer!: GameObjectRenderer

    /**
     * The terrain renderer
     */
    private terrainRenderer!: TerrainRenderer

    /**
     * The skybox renderer
     */
    private skyboxRenderer!: SkyboxRenderer

    /**
     * The water renderer
     */
    private waterRenderer!: WaterRenderer

    /**
     * The water frame buffers
     */
    private waterFrameBuffers: WaterFrameBuffers = new WaterFrameBuffers()

    /**
     * The camera
     */
    private camera!: Camera
    
    /**
     * The player
     */
    private player!: Player

    public initialize = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            // Construct all renderers
            this.gameObjectRenderer = new GameObjectRenderer()
            this.terrainRenderer = new TerrainRenderer()
            this.waterRenderer = new WaterRenderer(this.waterFrameBuffers)
            this.skyboxRenderer = new SkyboxRenderer('day5', 'dawn23', 'rich_mixed_nebulae')
            this.masterRenderer = new MasterRenderer(this.gameObjectRenderer, this.terrainRenderer, this.skyboxRenderer)

            // Initialize all renderers
            Promise.all([
                this.gameObjectRenderer.initialize('game-object'),
                this.terrainRenderer.initialize('terrain'),
                this.skyboxRenderer.initialize('skybox'),
                this.waterRenderer.initialize('water')
            ]).then(() => {

                // Load the player
                OBJLoader.loadObjModel('cube', {
                    texture: Loader.loadColorAsTexture(new Vector4(255, 255, 255, 255))
                }).then((player) => {
                    this.player = new Player(
                        player,
                        {
                            position: new Vector3(5, 0, 5),
                            rotation: new Vector3(0, 0, 0),
                            scale: new Vector3(1, 1, 1)
                        }
                    )

                    // Set the camera
                    this.camera = new Camera(this.player)

                    // Initialize the main scene
                    MasterRenderer.loadScene('main')

                    // Done with initialization
                    resolve()
                })
            }).catch(() => {
                throw new Error('Could not load renderer.')
            })
        })
    }

    public loop = () => {
        // TODO: base sun color and position on current scene
        // Update the sun
        if (MasterRenderer.sun) {
            MasterRenderer.sun.color = Environment.getSunColor()
            MasterRenderer.sun.position = Environment.getSunPosition()
        }

        // Update the environment
        Environment.update()
        
        // Determine the terrain the player is on
        let playerTerrain: Terrain|null = null
        for (const terrain of MasterRenderer.terrains) {
            const xCoordinate = Math.floor(this.player.transform.position.x / Terrain.SIZE)
            const zCoordinate = Math.floor(this.player.transform.position.z / Terrain.SIZE)

            if (terrain.x === xCoordinate && terrain.z === zCoordinate) {
                playerTerrain = terrain
                break
            }
        }

        // Move the player and camera
        this.player.move(playerTerrain)
        this.camera.move()

        // Update the skybox
        this.skyboxRenderer.update(this.camera)

        // Render everything
        if (MasterRenderer.currentScene !== null) {
            this.masterRenderer.render(this.player, this.camera)
        }
    }

    public cleanUp = () => {
        Loader.cleanUp()
        this.masterRenderer.cleanUp()
    }
}