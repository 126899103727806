import Vector2 from '../math/Vector2'
import Vector3 from '../math/Vector3'

/**
 * All maths functionality.
 * 
 * @author Stan Hurks
 */
export default class Maths {

    /**
     * Convert from degrees to radians
     */
    public static toRadians (degrees: number): number {
        return degrees * Math.PI / 180
    };

    /**
     * Convert from degrees to radians
     */
    public static toDegrees (radians: number): number {
        return radians * 180 / Math.PI
    }

    /**
     * Calculates the barycentric height value for 3 vertex points and a 2d point.
     */
    public static baryCentric(point1: Vector3, point2: Vector3, point3: Vector3, position: Vector2): number {
        const determinant: number = (point2.z - point3.z) * (point1.x - point3.x) + (point3.x - point2.x) * (point1.z - point3.z)
        const length1: number = ((point2.z - point3.z) * (position.x - point3.x) + (point3.x - point2.x) * (position.y - point3.z)) / determinant
        const length2: number = ((point3.z - point1.z) * (position.x - point3.x) + (point1.x - point3.x) * (position.y - point3.z)) / determinant
        const length3: number = 1.0 - length1 - length2
        return length1 * point1.y + length2 * point2.y + length3 * point3.y
    }
}