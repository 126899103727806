/**
 * This class contains information for specific features and whether
 * they are supported by the browser. It also contains browser related functionality.
 * 
 * @author Stan Hurks
 */
export default class Browser {

    /**
     * Whether or not the passive event listener option is supported.
     */
    public static passiveOption: boolean = false

    /**
     * Checks whether or not firefox is used as a browser
     */
    public static isFirefox: boolean = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

    /**
     * Check whether the browser is opera
     * ref: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
     */
    public static isOpera: boolean = (!!window['opr'] && !!window['opr'].addons) || !!window['opera'] || navigator.userAgent.indexOf(' OPR/') >= 0

    /**
     * Check whether the browser is chrome
     * ref: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
     */
    public static isChrome: boolean = !!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime)

    /**
     * Checks whether the browser is safari
     * ref: https://stackoverflow.com/questions/39120772/how-to-detect-safari-10-browser-in-javascript/39621764#39621764
     */
    public static isSafari: boolean = Object.prototype.toString.call(window['HTMLElement']).indexOf('Constructor') > 0 || (!Browser.isChrome && !Browser.isOpera && window['webkitAudioContext'] !== undefined)

    /**
     * Initialize the class
     */
    public static initialize(): void {
        Browser.checkPassiveOptionSupport()
    }

    /**
     * Calculate the width of scrollbars in the users browser
     */
    public static calculateScrollBarWidth(): number {
        var scrollDiv = document.createElement('div')
        scrollDiv.style.width = '100px'
        scrollDiv.style.height = '100px'
        scrollDiv.style.overflow = 'scroll'
        scrollDiv.style.position = 'absolute'
        scrollDiv.style.top = '-9999px'
        document.body.appendChild(scrollDiv)
        var scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
        document.body.removeChild(scrollDiv)
        return scrollBarWidth
    }

    /**
     * Checks if the passive option is supported
     */
    private static checkPassiveOptionSupport(): void {
        const testEventListener = (): void => {}
        try {
            const opts = Object.defineProperty({}, 'passive', {
                // eslint-disable-next-line
                get: () => {
                    Browser.passiveOption = true
                }
            })
            window.addEventListener('test', testEventListener, opts)
        } catch (ex) {
            console.error(ex)
            window.removeEventListener('test', testEventListener)
        }
        window.removeEventListener('test', testEventListener)
    }
}