import Renderer from '../Renderer'
import WaterFrameBuffers from '../water/WaterFrameBuffers'
import GameObjectModelComponent from '../../game-object/GameObjectModelComponent'
import Loader from '../../game-object/loader/Loader'
import WaterTile from '../water/WaterTile'
import Camera from '../../game-object/Camera'
import WebGL from '../../core/WebGL'
import Matrices from '../../math/Matrices'
import Canvas from '../../core/Canvas'
import Vector3 from '../../math/Vector3'
import Matrix4 from '../../math/Matrix4'
import Sun from '../../game-object/Sun'

/**
 * The renderer for water tiles.
 * 
 * @author Stan Hurks
 */
export default class WaterRenderer extends Renderer {

    /**
     * The wave speed
     */
    private static WAVE_SPEED: number = 0.03

    /**
     * The uniform location for the transformation matrix
     */
    private locationTransformationMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the projetion matrix
     */
    private locationProjectionMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the view matrix
     */
    private locationViewMatrix!: WebGLUniformLocation

    /**
     * The uniform location for the dudv texture
     */
    private locationDudvTexture!: WebGLUniformLocation

    /**
     * The uniform location for the normal map texture
     */
    private locationNormalMapTexture!: WebGLUniformLocation

    /**
     * The uniform location for the move factor
     */
    private locationMoveFactor!: WebGLUniformLocation

    /**
     * The uniform location for the water size
     */
    private locationWaterSize!: WebGLUniformLocation

    /**
     * The uniform location for the camera position
     */
    private locationCameraPosition!: WebGLUniformLocation

    /**
     * The uniform location for the sun position
     */
    private locationSunPosition!: WebGLUniformLocation

    /**
     * The uniform location for the sun color
     */
    private locationSunColor!: WebGLUniformLocation

    /**
     * The uniform location for the far plane
     */
    private locationFarPlane!: WebGLUniformLocation

    /**
     * The uniform location for the near plane
     */
    private locationNearPlane!: WebGLUniformLocation

    /**
     * The uniform location for the reflection texture
     */
    private locationReflectionTexture!: WebGLUniformLocation

    /**
     * The uniform location for the refraction texture
     */
    private locationRefractionTexture!: WebGLUniformLocation

    /**
     * The uniform location for the depth map texture
     */
    private locationDepthMapTexture!: WebGLUniformLocation

    /**
     * The uniform location for the water frame buffers
     */
    private waterFrameBuffers: WaterFrameBuffers

    /**
     * The component of a water tile
     */
    private component: GameObjectModelComponent = Loader.loadPositionsToVao([-1, -1, -1, 1, 1, -1, 1, -1, -1, 1, 1, 1], 2)

    /**
     * The move factor of the water
     */
    private moveFactor: number = 0

    /**
     * The dudv map
     */
    private dudvMap: WebGLTexture = Loader.loadTexture('water/water_dudv', false)

    /**
     * The normal map
     */
    private normalMap: WebGLTexture = Loader.loadTexture('water/water_normal', false)

    public constructor (waterFrameBuffers: WaterFrameBuffers) {
        super()
        this.waterFrameBuffers = waterFrameBuffers
    }

    /**
     * Render the water
     */
    public render = (water: WaterTile[], camera: Camera, sun: Sun) => {
        const gl = WebGL.context
        this.enable()

        this.loadViewMatrix(Matrices.createViewMatrix(camera))
        this.loadCameraPosition(camera)
        this.loadProjectionInformation(Matrices.createProjectionMatrix())
        this.moveFactor += WaterRenderer.WAVE_SPEED * Canvas.deltaTimeMS / 1000
        this.moveFactor %= 1
        this.loadMoveFactor(this.moveFactor)
        this.loadSunInformation(sun)

        gl.bindBuffer(gl.ARRAY_BUFFER, this.component.arrayBuffer as WebGLBuffer)

        var positionAttribLocation = gl.getAttribLocation(this.program, "position")
        gl.vertexAttribPointer(positionAttribLocation, 2, gl.FLOAT, false, 2 * Float32Array.BYTES_PER_ELEMENT, 0)
        gl.enableVertexAttribArray(positionAttribLocation)

        gl.activeTexture(gl.TEXTURE0)
        gl.bindTexture(gl.TEXTURE_2D, this.waterFrameBuffers.reflectionTexture)

        gl.activeTexture(gl.TEXTURE1)
        gl.bindTexture(gl.TEXTURE_2D, this.waterFrameBuffers.refractionTexture)

        gl.activeTexture(gl.TEXTURE2)
        gl.bindTexture(gl.TEXTURE_2D, this.dudvMap)

        gl.activeTexture(gl.TEXTURE3)
        gl.bindTexture(gl.TEXTURE_2D, this.normalMap)

        gl.activeTexture(gl.TEXTURE4)
        gl.bindTexture(gl.TEXTURE_2D, this.waterFrameBuffers.refractionDepthTexture)

        gl.enable(gl.BLEND)
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)

        water.forEach(waterTile => {
            this.loadTransformationMatrix(Matrices.createTransformationMatrix(
                {
                    position: new Vector3(waterTile.position.x, waterTile.position.y, waterTile.position.z),
                    rotation: new Vector3(0, 0, 0),
                    scale: new Vector3(waterTile.scale, waterTile.scale, waterTile.scale)
                }
            ))
            this.loadWaterSize(waterTile.scale)
            gl.drawArrays(gl.TRIANGLES, 0, this.component.indicesCount)
        })

        gl.disable(gl.BLEND)
        gl.disableVertexAttribArray(positionAttribLocation)
        gl.bindBuffer(gl.ARRAY_BUFFER, null)
        this.disable()
    }

    /**
     * Load the sun information to glsl.
     */
    public loadSunInformation = (sun: Sun) => {
        this.loadVector3(this.locationSunColor, sun.color)
        this.loadVector3(this.locationSunPosition, sun.position)
    }

    /**
     * Load the camera position
     */
    public loadCameraPosition = (camera: Camera) => {
        this.loadVector3(this.locationCameraPosition, camera.position)
    }

    /**
     * Load the water size to glsl
     */
    public loadWaterSize = (waterSize: number) => {
        this.loadFloat(this.locationWaterSize, waterSize)
    }

    /**
     * Load the move factor
     */
    public loadMoveFactor = (moveFactor: number) => {
        this.loadFloat(this.locationMoveFactor, moveFactor)
    }

    /**
     * Load the view matrix to glsl.
     */
    public loadViewMatrix = (matrix: Matrix4) => {
        this.loadMatrix(this.locationViewMatrix, matrix)
    }

    /**
     * Load the transformation matrix to glsl.
     */
    public loadTransformationMatrix = (matrix: Matrix4) => {
        this.loadMatrix(this.locationTransformationMatrix, matrix)
    }

    /**
     * Load the projection matrix to glsl
     */
    public loadProjectionInformation = (projectionMatrix: Matrix4) => {
        this.loadMatrix(this.locationProjectionMatrix, projectionMatrix)
        this.loadFloat(this.locationFarPlane, Matrices.PROJECTION_MATRIX_FAR_PLANE)
        this.loadFloat(this.locationNearPlane, Matrices.PROJECTION_MATRIX_NEAR_PLANE)
    }

    protected getAllUniformLocations = () => {
        this.locationProjectionMatrix = this.getUniformLocation('projectionMatrix')
        this.locationTransformationMatrix = this.getUniformLocation('transformationMatrix')
        this.locationViewMatrix = this.getUniformLocation('viewMatrix')
        this.locationMoveFactor = this.getUniformLocation('moveFactor')
        this.locationWaterSize = this.getUniformLocation('waterSize')
        this.locationCameraPosition = this.getUniformLocation('cameraPosition')
        this.locationSunColor = this.getUniformLocation('sunColor')
        this.locationSunPosition = this.getUniformLocation('sunPosition')
        this.locationFarPlane = this.getUniformLocation('farPlane')
        this.locationNearPlane = this.getUniformLocation('nearPlane')

        this.locationReflectionTexture = this.getUniformLocation('reflectionTexture')
        this.loadInteger(this.locationReflectionTexture, 0)

        this.locationRefractionTexture = this.getUniformLocation('refractionTexture')
        this.loadInteger(this.locationRefractionTexture, 1)

        this.locationDudvTexture = this.getUniformLocation('dudvTexture')
        this.loadInteger(this.locationDudvTexture, 2)

        this.locationNormalMapTexture = this.getUniformLocation('normalMapTexture')
        this.loadInteger(this.locationNormalMapTexture, 3)

        this.locationDepthMapTexture = this.getUniformLocation('depthMapTexture')
        this.loadInteger(this.locationDepthMapTexture, 4)
    }
}