import Assets from '../../../core/Assets'
import Loader from './Loader'
import GameObjectModel from '../../game-object/GameObjectModel'
import GameObjectModelComponent from '../../game-object/GameObjectModelComponent'
import GameObjectTexture from '../../game-object/GameObjectTexture'

/**
 * The loader responsible for loading .obj files.
 * 
 * @author Stan Hurks
 */
export default class OBJLoader {

    /**
     * All loaded .obj models
     */
    private static models: GameObjectModel[] = []

    /**
     * Load an .obj model
     */
    public static loadObjModel (objName: string, texture: GameObjectTexture): Promise<GameObjectModel> {
        return new Promise<GameObjectModel> ((resolve) => {
            const components: GameObjectModelComponent[] = []
            const objLocation = `models/obj/${objName}.obj`
            
            const objModel = OBJLoader.models.filter((v) => v.name === objName)[0]
            if (objModel !== undefined) {
                resolve(objModel)
            }
            Assets.load(objLocation).then(response => {
                const fileContent: string[] = response.split('\n')
                
                const componentNames: string[] = []

                const vertices: number[][] = []
                const textureCoordinates: number[] = []
                const normals: number[] = []
                const indices: number[][] = []

                const orderedTextureCoordinates: number[][] = []
                const orderedNormals: number[][] = []

                let previousVerticesCount: number = 0
                let previousTextureCoordinateCount: number = 0
                let previousNormalsCount: number = 0

                let firstFaceDone: boolean = false
                
                for (const lineIndex in fileContent) {
                    const line : string = fileContent[lineIndex]
                    const lineSplit : string[] = line.split(' ')

                    if (line.trim().length === 0) {
                        continue;
                    }

                    if (line.startsWith("v ")) {
                        for (let i : number = 0; i < 3; i ++) {
                            vertices[componentNames.length - 1].push(Number(lineSplit[i + 1])) 
                        }
                    }
                    else if (line.startsWith("vt ")) {
                        for (let i : number = 0; i < 2; i ++) {
                            textureCoordinates.push(Number(lineSplit[i + 1]))
                        }
                    }
                    else if (line.startsWith("vn ")) {
                        for (let i : number = 0; i < 3; i ++) {
                            normals.push(Number(lineSplit[i + 1]))
                        }
                    }
                    else if (line.startsWith("f ")) {
                        if (!firstFaceDone) {
                            if (componentNames.length > 1) {
                                previousNormalsCount += orderedNormals[componentNames.length - 2].length
                                previousVerticesCount += vertices[componentNames.length - 2].length
                                previousTextureCoordinateCount += orderedTextureCoordinates[componentNames.length - 2].length
                            }

                            orderedTextureCoordinates[componentNames.length - 1] = Array.from({length: vertices[componentNames.length - 1].length * 2 / 3}).map(() => 0)
                            orderedNormals[componentNames.length - 1] = Array.from({length: vertices[componentNames.length - 1].length}).map(() => 0)
                        }
                        
                        for (let i : number = 0; i < 3; i ++) {
                            OBJLoader.processFace(lineSplit[i + 1].split('/'), indices[componentNames.length - 1], textureCoordinates, normals, orderedTextureCoordinates[componentNames.length - 1], orderedNormals[componentNames.length - 1], previousVerticesCount, previousTextureCoordinateCount, previousNormalsCount)
                        }

                        firstFaceDone = true
                    }
                    else if (line.startsWith("o ")) {
                        firstFaceDone = false;
                        componentNames.push(line.replace("o ", ""));
                        
                        OBJLoader.processComponent(componentNames.length, vertices, orderedTextureCoordinates, orderedNormals, indices, components)
                    }
                }
                OBJLoader.processComponent(componentNames.length + 1, vertices, orderedTextureCoordinates, orderedNormals, indices, components)

                const model: GameObjectModel = {
                    name: objName,
                    components: components.map((component, i) => ({
                        ...component,
                        name: componentNames[i]
                    })),
                    texture
                }
                OBJLoader.models.push(model)
                resolve(model)
            }).catch(() => {
                throw new Error(`Could not load obj file: ${objName}`)
            })
        })
    }

    /**
     * Process a component
     */
    private static processComponent (
        componentNameCount : number,
        vertices: number[][],
        orderedTextureCoordinates: number[][],
        orderedNormals: number[][],
        indices: number[][],
        components: GameObjectModelComponent[]
    ) {
        if (vertices.length < componentNameCount) {
            vertices.push([])
        }

        if (orderedTextureCoordinates.length < componentNameCount) {
            orderedTextureCoordinates.push([])
        }

        if (orderedNormals.length < componentNameCount) {
            orderedNormals.push([])
        }

        if (indices.length < componentNameCount) {
            indices.push([])
        }

        if (componentNameCount === 1) {
            return
        }

        components.push(
            Loader.loadToVao(
                vertices[componentNameCount - 2],
                indices[componentNameCount - 2],
                orderedTextureCoordinates[componentNameCount - 2],
                orderedNormals[componentNameCount - 2]
            )
        )
    }

    /**
     * Process a face
     * @param vertex the split vertex data
     * @param indices the indices
     * @param textures the textures
     * @param normals the normals
     * @param orderedTextureCoordinates the ordered texture coordinates 
     * @param orderedNormals the ordered normals
     * @param previousVerticesCount the total amount of vertices in previous components
     * @param previousTextureCoordinateCount the total amount of texture coordinates in previous components
     * @param previousNormalsCount the total amount of normals in previous components
     */
    private static processFace (
        vertex: string[],
        indices: number[],
        textures: number[],
        normals: number[],
        orderedTextureCoordinates: number[],
        orderedNormals: number[], 
        previousVerticesCount: number,
        previousTextureCoordinateCount: number,
        previousNormalsCount: number
    ) {

        const currentVertexPointer: number = Number(vertex[0]) - 1
        indices.push(Number(vertex[0]) - 1 - previousVerticesCount / 3)

        orderedTextureCoordinates[currentVertexPointer * 2 - previousTextureCoordinateCount] = textures[(Number(vertex[1]) - 1) * 2]
        orderedTextureCoordinates[currentVertexPointer * 2 - previousTextureCoordinateCount + 1] = 1 - textures[(Number(vertex[1]) - 1) * 2 + 1]
    
        orderedNormals[(currentVertexPointer * 3) - previousNormalsCount] = normals[(Number(vertex[2]) - 1) * 3]
        orderedNormals[(currentVertexPointer * 3) - previousNormalsCount + 1] = normals[(Number(vertex[2]) - 1) * 3 + 1]
        orderedNormals[(currentVertexPointer * 3) - previousNormalsCount + 2] = normals[(Number(vertex[2]) - 1) * 3 + 2]
    }
}